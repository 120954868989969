import React, { FC } from 'react';
import styled from 'styled-components';
import { LineMedia } from '../types';
import RemoveButton from '../../ui-kit/RemoveButton';
import MediaThumbnail from '../common/MediaThumbnail';

const Element = styled.button<{currentMedia: LineMedia | null}>`
  border: none;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  background: ${({ theme }) => theme.gray.main} url("/assets/img/common/add-button-icon.png") no-repeat center;
  background-size: 50%;
  cursor: pointer;
  position: relative;
`;

const CurrentMediaWrapper = styled.div`
  position: relative;
  height: 60px;
`;

const RemoveButtonWrapper = styled.div`
  position: absolute;
  bottom: -9px;
  right: 0;
`;
interface Props {
  onClick: () => void;
  currentMedia: LineMedia | null;
}

const EnqueueMediaButton: FC<Props> = ({
  onClick,
  currentMedia,
}) => {
  if (currentMedia) {
    return (
      <CurrentMediaWrapper
        onClick={onClick}
        data-testid="lesson-text-media-attachment-enqueue-media-button"
      >
        <MediaThumbnail
          src={currentMedia.url}
          faded
        />
        <RemoveButtonWrapper>
          <RemoveButton
            data-testid="lesson-text-media-attachment-remove-media-button"
          />
        </RemoveButtonWrapper>
      </CurrentMediaWrapper>
    );
  }
  return (
    <Element
      onClick={onClick}
      currentMedia={currentMedia}
      data-testid="lesson-text-media-attachment-enqueue-media-button"
    />
  );
}

export default EnqueueMediaButton;
