import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Environments } from './types';

export interface EnvironmentSelectionState {
  type: Environments | null;
  options: Record<string, string> | null;
}

const initialState: EnvironmentSelectionState = {
  type: null,
  options: null,
}

export const environmentSelectionSlice = createSlice({
  name: 'environmentSelection',
  initialState,
  reducers: {
    setEnvironmentType: (state, action: PayloadAction<Environments>) => {
      state.type = action.payload;
    },
    setEnvironmentOptions: (state, action: PayloadAction<Record<string, string>>) => {
      state.options = action.payload;
    },
  },
});

export const {
  setEnvironmentType,
  setEnvironmentOptions,
} = environmentSelectionSlice.actions;

export default environmentSelectionSlice.reducer;
