import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Draft, Line, LineType } from '../types';
import {
  addLine, addLineAtIndex, deleteLine, updateLine,
} from '../lessonTextSlice';
import { toLine } from '../utils';
import { Actor } from '../../actors-selection/types';
import { draftFromLine, emptyDraft, toDraftsArray } from './utils';

interface Args {

}

interface Fields {
  draftLineIndex: number;
  createNewEmptyDraft: () => void;

  createDraftAtLineIndex: (index: number) => void;
  draftEditorIsVisible: boolean;
  handleDraftSave: () => void;

  handleDraftTextChange: (text: string) => void;
  handleDraftActorChange: (actor: Actor) => void;

  handleLineDelete: () => void;
  createDraftFromLine: (line: Line) => void;

  draft: Draft | null;
  draftHasChanged: boolean;
}

type Hook = (args: Args) => Fields;

const useDraftEditor: Hook = () => {
  const { lines } = useSelector((state: RootState) => state.lessonText);
  const dispatch = useDispatch();

  const [draftLineIndex, setDraftLineIndex] = useState<number>(-1);
  const [draftEditorIsVisible, setDraftEditorIsVisible] = useState<boolean>(false);

  const [draft, setDraft] = useState<Draft | null>(null);
  const [hasChanged, setHasChanged] = useState(false);

  const isNewDraft = draftLineIndex === lines.length;

  const closeDraftEditor = () => {
    setHasChanged(false);
    setDraftLineIndex(-1);
    setDraftEditorIsVisible(false);
    setDraft(null);
  }

  const createNewEmptyDraft = () => {
    setDraftLineIndex(lines.length);
    setDraftEditorIsVisible(true);
    setDraft(emptyDraft());
  }

  const createDraftFromLine = (line: Line) => {
    setDraftLineIndex(lines.indexOf(line));
    setDraftEditorIsVisible(true);
    setDraft(draftFromLine(line));
  }

  const handleDraftTextChange = (text: string) => {
    setDraft({
      ...draft,
      text,
    });
    setHasChanged(true);
  }

  const handleDraftActorChange = (actor: Actor) => {
    if (actor.uuid === 'pause') {
      handlePauseActorSelected(draft);
      return;
    }

    setDraft({
      ...draft,
      actor: actor.uuid,
    });
    setHasChanged(true);
  }

  const handlePauseActorSelected = (draft: Draft | null) => {
    if (!draft) {
      return;
    }

    const newDraft: Draft = {
      ...draft,
      text: '',
      actor: 'pause',
    }

    if (isNewDraft) {
      handleAddLine(newDraft, undefined, 'pause');
    } else {
      handleUpdateLine(newDraft, lines[draftLineIndex].uuid, 'pause');
    }
    setHasChanged(true);
    closeDraftEditor();
  }

  const handleDraftSave = () => {
    if (!draft) {
      return;
    }

    if (!hasChanged) {
      closeDraftEditor();
      return;
    }

    if (draft?.text?.length === 0) {
      closeDraftEditor();

      if (lines[draftLineIndex]) {
        dispatch(deleteLine(lines[draftLineIndex].uuid));
      }
      return;
    }

    if (isNewDraft) {
      handleAddLine(draft);
    } else {
      handleUpdateLine(draft, lines[draftLineIndex].uuid, 'text');
    }
    closeDraftEditor();
  }

  const handleAddLine = (draft: Draft, defaultUuid?: string, type?: LineType) => {
    if (type === 'pause') {
      dispatch(addLine(toLine(draft, undefined, type) as Line));
      return;
    }

    const drafts = toDraftsArray(draft, defaultUuid, type);
    drafts.forEach((draft) => {
      dispatch(addLine(toLine(draft)));
    });
  }

  const handleUpdateLine = (draft: Draft, defaultUuid?: string, type?: LineType) => {
    const drafts = toDraftsArray(draft, defaultUuid, type);
    drafts.forEach((draft, index) => {
      if (index <= 0) {
        dispatch(updateLine(toLine(draft, defaultUuid, type) as Line));
      } else {
        dispatch(addLineAtIndex({
          line: toLine(draft) as Line,
          index: draftLineIndex + index,
        }));
      }
    });
  }

  const createDraftAtLineIndex = (index: number) => {
    const draft = emptyDraft();

    dispatch(addLineAtIndex({
      line: toLine(draft) as Line,
      index,
    }));

    setDraftLineIndex(index);
    setDraftEditorIsVisible(true);
    setDraft(draft);
  }

  const handleLineDelete = () => {
    if (draftLineIndex < 0) {
      return;
    }

    dispatch(deleteLine(lines[draftLineIndex].uuid));
    closeDraftEditor();
  }

  return {
    draft,
    createNewEmptyDraft,
    createDraftFromLine,
    createDraftAtLineIndex,
    handleDraftTextChange,
    handleDraftActorChange,
    handleLineDelete,
    handleDraftSave,
    draftLineIndex,
    draftEditorIsVisible,
    draftHasChanged: hasChanged,
  }
}

export default useDraftEditor;
