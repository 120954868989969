import React, { FC } from 'react';
import styled from 'styled-components';
import { Add, Remove } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { Line } from './types';
import ActorThumbnail from './common/ActorThumbnail';
import OriginalTextWrapper from './common/TextWrapper';
import Thumbnail from './common/Thumbnail';
import { deleteLine, updateLineDuration } from './lessonTextSlice';

const TextWrapper = styled(OriginalTextWrapper)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.gray.label};
  height: 100%;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const CenteredLabel = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.gray.label};
  font-style: italic;
`;

const Duration = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  background: ${({ theme }) => theme.gray.chip};
  font-size: 12px;
  vertical-align: middle;
  box-sizing: border-box;
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;  
  
  .MuiSvgIcon-root {
    font-size: 16px !important;
  }
`;

const DurationLabel = styled.span`
  min-width: 30px;
  text-align: center;
`;
const IconButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  opacity: 0.4;
  width: 16px;
  height: 16px;
  &:disabled {
    cursor: default;
  }
`;

interface Props {
  line: Line;
  onClick?: () => void;
}

const PauseLine: FC<Props> = ({
  line,
  onClick,
}) => {
  const dispatch = useDispatch();

  const onDurationUpdate = (duration: number) => {
    if (duration <= 0) {
      dispatch(deleteLine(line.uuid));
    }
    dispatch(updateLineDuration({
      uuid: line.uuid,
      duration,
    }));
  }

  return (
    <>
      <ActorThumbnail
        onClick={onClick}
        data-testid="lesson-text-line-preview-pause-thumbnail"
      >
        <Thumbnail src="/assets/img/actor-selection/pause-thumbnail.png" />
      </ActorThumbnail>
      <TextWrapper
        onClick={onClick}
        data-testid="lesson-text-line-preview-text-content"
      >
        <LabelWrapper>
          <CenteredLabel>Pause</CenteredLabel>
        </LabelWrapper>
      </TextWrapper>
      <Duration>
        <IconButton>
          <Remove onClick={() => onDurationUpdate(line.duration - 0.5)} />
        </IconButton>
        <DurationLabel>
          {`${line.duration}s`}
        </DurationLabel>
        <IconButton>
          <Add onClick={() => onDurationUpdate(line.duration + 0.5)} />
        </IconButton>
      </Duration>
    </>
  );
}

export default PauseLine;
