import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  closestCenter, DndContext, DragEndEvent, MouseSensor, useSensor, useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DraftEditor from './draft-editor';
import LineContainer from './line-container';
import Subheading from '../ui-kit/Subheading';
import { RootState } from '../store/types';
import useDraftEditor from './draft-editor/useDraftEditor';
import AddNewLine from './common/AddNewLine';
import MediaAttachment from './media-attachment';
import { moveLine } from './lessonTextSlice';
import useMediaEditor from './useMediaEditor';
import LinePreview from './LinePreview';
import { computeIndicesOnDragEnd, isExistingDraft, isNewDraft } from './utils';

const LessonContent = styled.div`

`;

const LineWrapper = styled.div<{ elevate?: boolean }>`
  display: flex;
  align-items: center;
  
  z-index: ${({ elevate }) => (elevate ? 100 : 'unset')};
  position: relative;
`;

const Backdrop = styled.div
  .attrs({ 'data-testId': 'lesson-text-draft-editor-backdrop' })<{ open?: boolean}>`
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.5);
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

const LessonText: FC = () => {
  const {
    lines,
  } = useSelector((state: RootState) => state.lessonText);

  const dispatch = useDispatch();

  const sensors = useSensors(
    useSensor(MouseSensor),
  );

  const {
    createNewEmptyDraft,
    createDraftFromLine,
    createDraftAtLineIndex,

    handleDraftSave,
    handleDraftTextChange,
    handleDraftActorChange,

    handleLineDelete,

    draft,
    draftLineIndex,
    draftEditorIsVisible,
    draftHasChanged,
  } = useDraftEditor({});

  const {
    addMedia,
    handleEnqueueConfirm,
    clearMedia,
    handleMediaOptionChanged,
    selectedLineIndex,
    handleMediaEditStart,
    handleMediaEditEnd,
  } = useMediaEditor();

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    if (active.id !== over?.id) {
      const [activeIndex, overIndex] = computeIndicesOnDragEnd(e, lines);

      dispatch(moveLine({
        from: activeIndex,
        to: overIndex,
      }));
    }
  }

  return (
    <div>
      <Backdrop open={draftEditorIsVisible} onClick={() => handleDraftSave()} />
      <Subheading>
        <Trans i18nKey="lessonText.headTitle" />
      </Subheading>
      <LessonContent
        data-testid="lesson-text-lesson-content"
      >
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
          autoScroll
        >
          <SortableContext
            items={lines.map((line) => line.uuid)}
            strategy={verticalListSortingStrategy}
          >
            {lines.map((line, index) => (
              <LineWrapper
                key={line.uuid}
                elevate={index === draftLineIndex}
                data-testid="lesson-text-line-container-wrapper"
              >
                <LineContainer
                  id={line.uuid}
                  disableActions={index === draftLineIndex}
                  onAddNewLine={() => createDraftAtLineIndex(index + 1)}
                  aside={(
                    <MediaAttachment
                      lineIndex={index}
                      selectedLineMedia={selectedLineIndex}
                      addMedia={addMedia}
                      handleEnqueueConfirm={handleEnqueueConfirm}
                      handleMediaEditStart={() => handleMediaEditStart(index)}
                      handleMediaEditEnd={() => handleMediaEditEnd()}
                      handleMediaOptionChanged={handleMediaOptionChanged}
                      clearMedia={clearMedia}
                    />
                  )}
                >
                  {isExistingDraft(draftEditorIsVisible, draftLineIndex, index) && (
                    <DraftEditor
                      draft={draft!}
                      onSave={handleDraftSave}
                      onChange={handleDraftTextChange}
                      onActorChange={handleDraftActorChange}
                      hasChanged={draftHasChanged}
                      onDelete={() => handleLineDelete()}
                    />
                  )}
                  {index !== draftLineIndex && (
                    <LinePreview
                      line={line}
                      onRequestDraftEdit={() => createDraftFromLine(line)}
                    />
                  )}
                </LineContainer>
              </LineWrapper>
            ))}
          </SortableContext>
        </DndContext>
      </LessonContent>

      {isNewDraft(draftEditorIsVisible, draftLineIndex, lines) && (
        <LineWrapper elevate>
          <LineContainer id={draft?.id} disableActions>
            <DraftEditor
              draft={draft!}
              onSave={handleDraftSave}
              onChange={handleDraftTextChange}
              onActorChange={handleDraftActorChange}
              hasChanged={draftHasChanged}
            />
          </LineContainer>
        </LineWrapper>
      )}

      {!draftEditorIsVisible && (
        <AddNewLine onClick={() => createNewEmptyDraft()} />
      )}
    </div>
  );
}

export default LessonText;
