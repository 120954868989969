import {
  useEffect, useMemo, useState,
} from 'react';
import { object } from 'validator-fns';
import { useSelector } from 'react-redux';
import { Config, Step } from './types';
import { RootState } from '../store/types';

interface Fields {
  canGoToNextStep: boolean;
  canGoToPreviousStep: boolean;
}

interface Args {
  config: Config;
  currentStepIndex: number;
  currentStep: Step;
  totalSteps: number;
  bypassValidation?: boolean;
}
type Hook = (_: Args) => Fields;

const useStepValidation: Hook = ({
  config,
  currentStep,
  currentStepIndex,
  totalSteps,
  bypassValidation = false,
}) => {
  const state = useSelector((state: RootState) => state);

  // Assume step is valid if no constraints are defined
  const [stepIsValid, setStepIsValid] = useState(bypassValidation ? true : !currentStep.constraints);

  const canGoToNextStep = currentStepIndex < totalSteps - 1;
  const canGoToPreviousStep = currentStepIndex > 0;

  const currentStepConstraints = useMemo(() => config.steps[currentStepIndex].constraints, [currentStepIndex, config]);

  useEffect(() => {
    if (bypassValidation) {
      return;
    }

    const currentStepValues = state[currentStep.stepName as keyof RootState];

    const validate = object({
      ...currentStepConstraints,
    });

    validate({
      ...currentStepValues,
    }).then((validationResult) => {
      const { isValid } = validationResult;
      setStepIsValid(isValid);
    });
  }, [currentStepIndex, state, currentStep, currentStepConstraints, bypassValidation]);

  return {
    canGoToNextStep: stepIsValid && canGoToNextStep,
    canGoToPreviousStep,
  }
}

export default useStepValidation;
