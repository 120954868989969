export enum VideoLanguages {
  ENGLISH = 'english',
  GERMAN = 'german',
  FRENCH = 'french',
}

export interface VideoInformationStepData {
  title: string | null;
  description: string | null;
  tags: string[];
  language: VideoLanguages | null;
}
