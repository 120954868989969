import React, { ChangeEvent, FC, useMemo } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import Select, { SelectItem } from '../ui-kit/Select';
import { ItemContainer as OriginalItemContainer, Thumbnail } from './common';
import {
  Actor,
} from './types';
import { ActorData, actorsData } from './data';
import RemoveButton from '../ui-kit/RemoveButton';
import Input from '../ui-kit/Input';

const RemoveButtonWrapper = styled.div`
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  opacity: 0;
  transition: 0.1s ease opacity;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  
  &:hover {
    ${RemoveButtonWrapper} {
      opacity: 1;
    }
  }
`;

const OptionsWrapper = styled.div`
  width: 100%;
`;

const ItemContainer = styled(OriginalItemContainer)`
  cursor: default;
`;

interface Props {
  actor: Actor;
  onChange: (actor: Actor, option: Record<string, string>) => void;
  onDelete: (actor: Actor) => void;
}

const ActorOptions: FC<Props> = ({
  actor,
  onChange,
  onDelete,
}) => {
  const { t } = useTranslation();

  const actorData = useMemo(() => actorsData.actors.find((actorData) => actorData.slug === actor.slug), [actor]);

  if (!actorData) {
    return (
      <Wrapper>
        <pre>Actor data not found!</pre>
      </Wrapper>
    );
  }

  const onActorOptionChange = (e: SelectChangeEvent<unknown>, optionName: string) => {
    const optionValue = e.target.value as string;
    const optionPayload: Record<string, string> = {
      [optionName]: optionValue,
    }

    onChange(actor, optionPayload);
  }

  const onActorNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const optionPayload: Record<string, string> = {
      characterName: e.target.value,
    }

    onChange(actor, optionPayload);
  }

  return (
    <Wrapper>
      <ItemContainer>
        <Thumbnail src={actor.fullBodyPreviewUrl} />
        <RemoveButtonWrapper>
          <RemoveButton onClick={() => onDelete(actor)} />
        </RemoveButtonWrapper>
      </ItemContainer>
      <OptionsWrapper>
        <Input
          name="actorName"
          label={t('actorsSelection.name.label')}
          // label="Actor Name"
          onChange={onActorNameChange}
          value={actor.options?.characterName || ''}
          data-testid={`actors-selection-actor-${actor.name}-name-input`}
        />
        {actorData.options && Object.keys(actorData.options)
          .map((optionName) => (
            <Select
              key={optionName}
              label={t(`actorsSelection.${optionName}.label`)}
              data-testid={`actors-selection-actor-${actor.name}-${optionName}-select`}
              value={actor.options && actor.options[optionName]}
              onChange={(e) => onActorOptionChange(e, optionName)}
            >
              {actorData.options && actorData.options[(optionName as keyof ActorData['options'])].map((optionValue) => (
                <SelectItem value={optionValue.slug} key={`${actor.uuid}-${optionName}-${optionValue.slug}`}>
                  <Trans i18nKey={`actorsSelection.${optionName}.options.${optionValue.slug}`} />
                </SelectItem>
              ))}
            </Select>
          ))}
      </OptionsWrapper>
    </Wrapper>
  );
}

export default ActorOptions;
