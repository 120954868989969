import React, { FC } from 'react';
import { Button as OriginalButton, ButtonProps } from '@mui/material';

const Button: FC<ButtonProps> = (props) => (
  <OriginalButton
    color="primary"
    variant="contained"
    sx={{
      borderRadius: '8px',
    }}
    {...props}
  />
)

export default Button;
