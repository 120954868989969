/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  ChangeEventHandler,
  FC, useEffect, useMemo, useRef,
} from 'react';
import styled, { css } from 'styled-components';
import { Line, LineMediaList } from '../lesson-text-v2/types';
import { Block } from './types';
import { durationToPercentage, getLineIndexForPosition, percentageToDuration } from './utils';

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.gray.main};
  display: flex;
  padding: 0 2px;
  position: relative;
  margin-bottom: 1em;
`;

const BlockItem = styled.div<{ percent: number, hasAttachment: boolean, isPauseBlock: boolean }>`
  border-radius: 4px;
  background: white;
  height: 40px;
  margin: 4px 2px;
  width: ${({ percent }) => `${percent}%`};
  box-shadow: 1px 1px 4px rgba(0,0,0,0.1);
  position: relative;
  
  ${({ hasAttachment }) => hasAttachment && css`
    &:after {
      content: '';
      background: url('/assets/img/review/image-attachment.png');
      background-size: contain;
      width: 13px;
      height: 13px;
      position: absolute;
      bottom: 3px;
      left: 3px;
    }
  `}
  
  ${({ isPauseBlock }) => isPauseBlock && css`
    &:before {
      content: '';
      background: url('/assets/img/review/pause-type-line.png');
      background-size: contain;
      width: 13px;
      height: 13px;
      position: absolute;
      bottom: 3px;
      right: 3px;
    }
  `}
`;

const SeekbarWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;

const Seekbar = styled.input`
  width: 100%;
  height: 48px;
  
  appearance: none;
  background: transparent;
  margin: 0;
  
  // Firefox needs different styling
  &::-moz-range-thumb {
    appearance: none;
    width: 0;
    height: 44px;
    border: 2px solid black;
    background: black;
    border-radius: 2px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  } 
  
  &::-webkit-slider-thumb {
    appearance: none;
    width: 2px;
    height: 48px;
    border: 2px solid black;
    background: black;
    border-radius: 2px;
    position: relative;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  }
`;

const Indicator = styled.div`
  &:after {
    --indicator-width: 36px;
    content: var(--current-time, '00:00');
    position: absolute;
    z-index: 1;
    width: var(--indicator-width);
    height: 15px;
    color: white;
    background: black;
    font-size: 11px;
    text-align: center;

    border-radius: 0 0 4px 4px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

    left: clamp(
            var(--indicator-width) / 2,
            max(var(--seekbar-position), var(--indicator-width) / 2),
            calc(100% - var(--indicator-width) / 2)
    );

    transform: translateX(
            min(var(--seekbar-position), var(--indicator-width) / 2 * -1)
    );

    margin-top: -8px;
  }
`;

interface Props {
  lines: Line[];
  media: LineMediaList;
  onLineActive: (lineIndex: number) => void;
}

const Timeline: FC<Props> = ({
  lines,
  media,
  onLineActive,
}) => {
  const currentLineIndex = useRef<number>(0);
  const indicatorRef = useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line
  const totalDuration = useMemo(() => lines.reduce((total, line) => total + line.duration, 0), []);

  const blocks = useMemo(
    () => lines.map((line, index) => ({
      uuid: line.uuid,
      size: durationToPercentage(line.duration, totalDuration),
      duration: line.duration,
      hasAttachment: !!media[index],
      type: line.type,
    }) as Block)
    // eslint-disable-next-line
  , []);

  useEffect(() => {
    onLineActive(0);
    // eslint-disable-next-line
  }, []);

  const handleSeekbarPositionChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!indicatorRef.current) {
      return;
    }

    const position = e.currentTarget.value;
    const lineIndex = getLineIndexForPosition(parseFloat(position), blocks, totalDuration);

    if (currentLineIndex.current !== lineIndex) {
      currentLineIndex.current = lineIndex;
      onLineActive(lineIndex);
    }

    // @ts-ignore
    indicatorRef.current.style = `--seekbar-position: ${position}%; --current-time: '${percentageToDuration(position, totalDuration)}'`;
  }

  return (
    <Wrapper>
      {blocks.map((block) => (
        <BlockItem
          key={block.uuid}
          percent={block.size}
          hasAttachment={block.hasAttachment}
          isPauseBlock={block.type === 'pause'}
        />
      ))}
      <SeekbarWrapper>
        <Seekbar
          type="range"
          min={0}
          max={100}
          step={0.1}
          onChange={handleSeekbarPositionChange}
          defaultValue={0}
        />
        <Indicator ref={indicatorRef} />
      </SeekbarWrapper>
    </Wrapper>
  );
};

export default Timeline;
