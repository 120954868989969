export interface StyleData {
  name: string;
  slug: string;
  thumbnailUrl: string;
  previewUrl: string;
}

interface Data {
  styles: StyleData[];
}

// eslint-disable-next-line import/prefer-default-export
export const displayStyleData: Data = {
  styles: [
    {
      name: 'epic',
      slug: 'epic',
      thumbnailUrl: '/assets/img/video-styles/epic.png',
      previewUrl: '/assets/img/video-styles/epic.gif',
    },
    {
      name: 'flick',
      slug: 'flick',
      thumbnailUrl: '/assets/img/video-styles/flick.png',
      previewUrl: '/assets/img/video-styles/flick.gif',
    },
    {
      name: 'grammy',
      slug: 'grammy',
      thumbnailUrl: '/assets/img/video-styles/grammy.png',
      previewUrl: '/assets/img/video-styles/grammy.gif',
    },
    {
      name: 'launch',
      slug: 'launch',
      thumbnailUrl: '/assets/img/video-styles/launch.png',
      previewUrl: '/assets/img/video-styles/launch.gif',
    },
    {
      name: 'light',
      slug: 'light',
      thumbnailUrl: '/assets/img/video-styles/light.png',
      previewUrl: '/assets/img/video-styles/light.gif',
    },
    {
      name: 'sliced',
      slug: 'sliced',
      thumbnailUrl: '/assets/img/video-styles/sliced.png',
      previewUrl: '/assets/img/video-styles/sliced.gif',
    },
    {
      name: 'typed',
      slug: 'typed',
      thumbnailUrl: '/assets/img/video-styles/typed.png',
      previewUrl: '/assets/img/video-styles/typed.gif',
    },
  ],
}
