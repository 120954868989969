import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 630px;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

const PlayButton = styled.button`
  border: none;
  width: 88px;
  height: 88px;
  background: url('/assets/img/review/play-button.png') no-repeat center;
  background-size: contain;
  z-index: 1;
`;

const Player: FC = () => (
  <Wrapper>
    <Image src="/assets/img/review/video-preview.png" />
    <PlayButton />
  </Wrapper>
)

export default Player;
