import React, {
  FC, PropsWithChildren, useEffect, useRef,
} from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicator from '../common/DragIndicator';
import AddIndicator from '../common/AddIndicator';

const Wrapper = styled.div<{ isDragging?: boolean }>`
  width: 100%;
  display: flex;
  z-index: ${({ isDragging = false }) => (isDragging ? 100 : 1)};
`;

const LineWrapper = styled.div<{ isDragging?: boolean }>`
  display: flex;
  background-color: ${({ theme }) => theme.gray.main};
  padding: 8px;
  margin: 0.5em 1em 0.5em 0;
  border-radius: 8px;
  width: 100%;
  word-break: break-word;
  position: relative;
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: ${({ isDragging = false }) => (isDragging ? '0 0 9px 0 rgba(0, 0, 0, 0.2)' : 'none')};
  align-items: flex-start;
`;

const ActionContainer = styled.div`
  display: flex;
  height: 100%;
  max-height: 64px;
`;

const ActionHandle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 100%;
  margin-right: 10px;
  border: none;
  background: transparent;
  transition: opacity 0.1s ease-in-out;
  
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
`;

const DragHandleWrapper = styled(ActionHandle)`
  cursor: grab;
`;

const AddHandleWrapper = styled(ActionHandle)`
  cursor: pointer;
`;

const Aside = styled.div`
  display: flex;
  align-items: center;
  min-width: 250px;
`;

interface Props {
  id?: string;
  disableActions?: boolean;
  hideActions?: boolean;
  aside?: React.ReactNode;
  onAddNewLine?: () => void;
}
const LineContainer: FC<PropsWithChildren<Props>> = ({
  children,
  disableActions = false,
  hideActions = false,
  id = '',
  aside,
  onAddNewLine,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleAddNewLine = () => {
    if (onAddNewLine) {
      onAddNewLine();
    }
  }

  return (
    <Wrapper
      ref={setNodeRef}
      style={style}
      isDragging={isDragging}
    >
      <LineWrapper isDragging={isDragging}>
        {!hideActions && (
          <ActionContainer>
            <AddHandleWrapper
              disabled={disableActions}
              onClick={handleAddNewLine}
              data-testid="lesson-text-line-container-add-line-at-index"
            >
              <AddIndicator />
            </AddHandleWrapper>
            <DragHandleWrapper
              {...attributes}
              {...listeners}
              disabled={disableActions}
              data-testid="lesson-text-line-container-drag-handle"
            >
              <DragIndicator />
            </DragHandleWrapper>
          </ActionContainer>
        )}
        {children}
      </LineWrapper>
      <Aside>
        {aside}
      </Aside>
    </Wrapper>
  );
}
export default LineContainer;
