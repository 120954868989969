import { DefaultTheme } from 'styled-components';
import { createTheme } from '@mui/material/styles';

const theme = {
  primary: {
    main: '#457370',
  },
  secondary: {
    main: '#445B8F',
  },
  tertiary: {
    main: '#FFFCBC',
    light: '#fffccd',
    dark: '#dad76f',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  gray: {
    light: '#f8f8f8',
    main: '#ebebeb',
    dark: '#666666',
    chip: '#D9D9D9',
    label: '#636363',
  },
}

export const muiTheme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    ...theme,
  },
})

export const scTheme: DefaultTheme = {
  ...theme,
}

export default theme;
