import styled from 'styled-components';

const MediaThumbnail = styled.img<{ selected?: boolean, faded?: boolean, disabled?: boolean }>`
  width: 60px;
  height: 60px;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  object-fit: contain;
  border-radius: 8px;
  outline: ${({ selected, theme }) => (selected ? `${theme.secondary.main} solid 2px` : '0')};
  opacity: ${({ faded = false }) => (faded ? '0.4' : '1')};
`;

export default MediaThumbnail;
