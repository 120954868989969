import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { canBeEnqueued } from '../utils';
import {
  AddMediaArgs,
} from '../types';
import EnqueueMediaButton from './EnqueueMediaButton';
import MediaButton from './MediaButton';
import HelpBoxTrigger from '../../helpbox/HelpBoxTrigger';
import { RootState } from '../../store/types';

interface Props {
  lineIndex: number;
  selectedLineMedia: number | null;
  handleEnqueueConfirm: (lineIndex: number, isEnqueued: boolean) => void;
  addMedia: (args: AddMediaArgs) => void;
  handleMediaEditStart: (lineIndex: number) => void;
  handleMediaEditEnd: () => void;
  handleMediaOptionChanged: (name: string, value: string) => void;
  clearMedia: () => void;
}

const MediaAttachment: FC<Props> = ({
  lineIndex,
  selectedLineMedia,
  handleEnqueueConfirm,
  addMedia,
  handleMediaEditStart,
  handleMediaEditEnd,
  handleMediaOptionChanged,
  clearMedia,
}) => {
  const {
    lines,
    media,
  } = useSelector((state: RootState) => state.lessonText);

  if (canBeEnqueued(lineIndex, selectedLineMedia, lines, media)) {
    return (
      <EnqueueMediaButton
        onClick={() => handleEnqueueConfirm(lineIndex, !!media[lineIndex])}
        currentMedia={media[lineIndex]}
      />
    );
  }

  return (
    <HelpBoxTrigger translationKey="lessonText.media.editMedia">
      <MediaButton
        media={media[lineIndex]}
        onMediaChanged={(url) => addMedia({
          url,
          lineIndex,
        })}
        isEditingMedia={lineIndex === selectedLineMedia}
        onMediaEditStart={() => handleMediaEditStart(lineIndex)}
        onMediaEditEnd={handleMediaEditEnd}
        onMediaOptionChanged={handleMediaOptionChanged}
        onClearMedia={() => clearMedia()}
      />
    </HelpBoxTrigger>
  )
}

export default MediaAttachment;
