import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import HorizontalScrollingGallery from '../ui-kit/HorizontalScrollingGallery';
import { ItemContainer, Thumbnail } from './common';
import { ActorData, actorsData } from './data';
import { Actor, ActorOptions } from './types';

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  margin-bottom: 1em;
`;

const AddButtonWrapper = styled.div`
  width: 100%;
  max-width: 185px; 
  display: flex;
  justify-content: center;
`;

const AddButton = styled.button`
  border: none;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  background: ${({ theme }) => theme.gray.main} url("/assets/img/common/add-button-icon.png") no-repeat center;
  background-size: 50%;
  cursor: pointer;
`;

const createActor = (actor: ActorData) => {
  const actorOptions: ActorOptions = {};

  Object.keys(actor.options || {}).forEach((actorOptionName) => {
    actorOptions[actorOptionName] = null;
  });

  actorOptions.characterName = actor.name;

  return ({
    uuid: uuid(),
    slug: actor.slug,
    name: actor.name,
    fullBodyPreviewUrl: actor.defaultPreviewUrl || '',
    thumbnailUrl: actor.thumbnailUrl || '',
    options: actorOptions,
  } as Actor);
}

interface Props {
  onSelected: (actor: Actor) => void;
  defaultCollapsed?: boolean;
}

const ActorsGallery: FC<Props> = ({
  onSelected,
  defaultCollapsed = true,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  if (collapsed) {
    return (
      <Wrapper>
        <AddButtonWrapper>
          <AddButton
            data-testid="actors-selection-add-button"
            onClick={() => setCollapsed(false)}
          />
        </AddButtonWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <HorizontalScrollingGallery transparent>
        {actorsData.actors.map((actorData) => (
          <ItemContainer
            key={actorData.slug}
            data-testid={`actors-selection-actor-${actorData.slug}`}
            onClick={() => onSelected(createActor(actorData))}
          >
            <Thumbnail src={actorData.thumbnailUrl} />
          </ItemContainer>
        ))}
      </HorizontalScrollingGallery>
    </Wrapper>
  );
}

export default ActorsGallery;
