import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.label<{ isSelected: boolean}>`
  display: block;
  width: 240px;
  transition: 0.1s ease opacity;
  position: relative;
  cursor: pointer;
  margin: 0 10px 0 0;
  
  &:last-of-type {
    margin-right: 0;
  }

  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.5')};

  &:hover {
    opacity: 1;
  }
  
  img {
    object-fit: cover;
    object-position: center;
    aspect-ratio: 1;
    width: 240px;
    min-width: 240px;
    height: 240px;
  }

  input { 
    display: none;
  }
  
  input:checked ~ div {
    box-sizing: border-box;
    :after {
      box-shadow: inset 0 0 0 4px ${({ theme }) => theme.primary.main};
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
`;

const BorderMagnet = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 240px;
  height: 240px;
`;

const Name = styled.div`
  text-align: center;
  font-weight: 400;
`;

interface Props {
  src: string;
  value: string;
  name: string;
  label?: string;
  isSelected: boolean;
}

const ThumbnailSelectItem: FC<Props> = ({
  src,
  value,
  name,
  label,
  isSelected,
  ...rest
}) => {
  const ref = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSelected]);

  return (
    <Wrapper
      ref={ref}
      isSelected={isSelected}
      // @ts-ignore
      data-testid={rest['data-testid']}
    >
      <ThumbnailWrapper>
        <input
          type="radio"
          name={name}
          value={value}
          defaultChecked={isSelected}

        />
        <BorderMagnet />
        <img src={src} alt="" />
      </ThumbnailWrapper>
      {label && <Name>{label}</Name>}
    </Wrapper>
  );
}

export default ThumbnailSelectItem;
