import React, { FC } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { Line } from '../lesson-text-v2/types';

const Wrapper = styled.div`

`;

const Label = styled.label`
  font-size: 12px;
`;

interface Props {
  line: Line | null;
}

const Transcript: FC<Props> = ({
  line,
}) => (
  <Wrapper>
    <Label>
      <Trans>
        Transcript
      </Trans>
    </Label>
    {line && (<p>{line.text}</p>)}
  </Wrapper>
)

export default Transcript;
