import React, { FC } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import Heading from '../ui-kit/Heading';
import Paragraph from '../ui-kit/Paragraph';
import Container from '../ui-kit/Container';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const WelcomeScreen: FC = () => (
  <Wrapper>
    <Heading>
      <Trans>
        Welcome
      </Trans>
    </Heading>

    <Container sm>
      <Paragraph>
        <Trans>
          Hello and welcome to the video creator wizard. This wizard will help you generate a video with your lesson content. All you need now is your lesson transcript.
        </Trans>
      </Paragraph>
    </Container>
  </Wrapper>
)

export default WelcomeScreen;
