import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler, useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Draft } from '../types';
import Thumbnail from '../common/Thumbnail';
import ActorSelection from './ActorSelection';
import { RootState } from '../../store/types';
import { Actor } from '../../actors-selection/types';
import Input from '../../ui-kit/Input';
import ActorThumbnail from '../common/ActorThumbnail';
import RemoveButton from '../../ui-kit/RemoveButton';
import { isPauseActor } from './utils';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const ActorSection = styled.div`
  display: flex;
`;

const TextInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 16px;
`;

interface Props {
    onSave: (draft: Draft) => void;
    draft: Draft;
    onChange: (text: string) => void;
    onActorChange: (actor: Actor) => void;
    hasChanged: boolean;
    onDelete?: () => void;
}

const DraftEditor: FC<Props> = ({
  onSave,
  onChange,
  onActorChange,
  draft,
  hasChanged,
  onDelete,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const actors = useSelector((state: RootState) => state.actorsSelection.actors);

  const selectedActor = actors.find((actor) => actor.uuid === draft.actor);

  const [actorSelectionOpen, setActorSelectionOpen] = useState(false);

  useEffect(() => {
    inputRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);
  const onActorSelected = (actor: Actor) => {
    onActorChange(actor);
    setActorSelectionOpen(false);
  }

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      onSave(draft);
    }

    if (e.code === 'Escape') {
      e.preventDefault();
      onSave(draft);
    }
  }

  const handleOnChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    onChange(e.target.value);
  }

  if (!draft) {
    return null;
  }

  return (
    <Wrapper ref={wrapperRef}>
      <ActorSection>
        <ActorThumbnail
          onClick={() => setActorSelectionOpen(!actorSelectionOpen)}
          data-testid="lesson-text-draft-editor-actor-thumbnail"
        >
          {isPauseActor(draft.actor) && (
            <Thumbnail src="/assets/img/actor-selection/pause-thumbnail.png" />
          )}
          {!isPauseActor(draft.actor) && (
            <Thumbnail src={selectedActor?.thumbnailUrl || '/assets/img/actor-selection/unknown-thumbnail.png'} />
          )}
        </ActorThumbnail>
        {actorSelectionOpen && (
          <ActorSelection
            actors={actors}
            onActorSelected={onActorSelected}
          />
        )}
      </ActorSection>
      <TextInputWrapper>
        <Input
          ref={inputRef}
          label={selectedActor?.options?.characterName || ''}
          disabled={isPauseActor(draft.actor)}
          value={isPauseActor(draft.actor) ? 'Pause' : draft.text}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          data-testid="lesson-text-draft-editor-input"
          margin="none"
          multiline
          autoFocus
        />
      </TextInputWrapper>
      {!!onDelete && (
        <DeleteButtonWrapper>
          <RemoveButton
            onClick={() => onDelete()}
            data-testid="lesson-text-draft-editor-delete-button"
          />
        </DeleteButtonWrapper>
      )}
    </Wrapper>
  );
}

export default DraftEditor;
