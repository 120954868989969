import styled from 'styled-components';

const RemoveButton = styled.button`
  border: none;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.gray.main} url("/assets/img/common/remove.png") no-repeat center;
  background-size: 50%;
  cursor: pointer;
`;

export default RemoveButton;
