import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Actor } from './types';

export interface ActorsSelectionState {
  actors: Actor[];
}

const initialState: ActorsSelectionState = {
  actors: [],
}

export const actorsSelectionSlice = createSlice({
  name: 'actorsSelection',
  initialState,
  reducers: {
    setActors: (state, action: PayloadAction<Actor[]>) => {
      state.actors = action.payload;
    },
  },
});

export const { setActors } = actorsSelectionSlice.actions;

export default actorsSelectionSlice.reducer;
