import React, { FC, useState } from 'react';
import styled from 'styled-components';
import useNavigation from './useNavigation';
import Navigation from './Navigation';
import Container from '../ui-kit/Container';
import HelpBox from '../helpbox';

import useStepValidation from './useStepValidation';
import config from './config';

const Layout = styled.div`
  display: grid;
  grid-template-rows: auto 80px;
  height: 100%;
`;

const StepLayout = styled.div<{ hideHelpBox: boolean }>`
  display: grid;
  grid-template-columns: ${({ hideHelpBox }) => (hideHelpBox ? 'auto' : '70% 30%')};
  overflow-y: auto;
  height: 100%;
  position: relative;
`;

const StepContent = styled.div`
  max-height: calc(100vh - 80px - 1em);
  overflow-y: auto;
`;

const HelpBoxWrapper = styled.div`
  padding: 1em;
`;

const ShowHelpBoxButton = styled.button`
  width: 50px;
  height: 50px;
  background: ${({ theme }) => theme.tertiary.main} url("/assets/img/common/help.png") no-repeat center;
  background-size: 30%;
  border: none;
  border-radius: 50px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 1em;
  cursor: pointer;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
`;

const App: FC = () => {
  const [helpBoxMinimized, setHelpBoxMinimized] = useState(true);

  const {
    goToNextStep,
    goToPreviousStep,
    currentStep,
    currentStepIndex,
    totalSteps,
  } = useNavigation({
    config,
    defaultStepIndex: 0,
  });

  const {
    canGoToNextStep,
    canGoToPreviousStep,
  } = useStepValidation({
    currentStep,
    config,
    currentStepIndex,
    totalSteps,
    bypassValidation: false,
  });

  const StepComponent = currentStep.Component;

  return (
    <Layout>
      <Container>
        <StepLayout hideHelpBox={currentStep.hideHelpBox || helpBoxMinimized}>
          <StepContent>
            <StepComponent />
          </StepContent>

          {!currentStep.hideHelpBox && !helpBoxMinimized && (
          <HelpBoxWrapper>
            <HelpBox
              onMinimize={() => setHelpBoxMinimized(true)}
              currentStepName={currentStep.stepName}
            />
          </HelpBoxWrapper>
          )}
          {!currentStep.hideHelpBox && helpBoxMinimized && (
            <ShowHelpBoxButton onClick={() => setHelpBoxMinimized(false)} />
          )}
        </StepLayout>
      </Container>
      <Container>
        <Navigation
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          canGoToNextStep={canGoToNextStep}
          canGoToPreviousStep={canGoToPreviousStep}
        />
      </Container>
    </Layout>
  )
}

export default App;
