import React, { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';
import { SelectChangeEvent } from '@mui/material';
import { LineMedia } from '../types';
import RemoveButton from '../../ui-kit/RemoveButton';
import MediaOptions from './MediaOptions';
import MediaThumbnail from '../common/MediaThumbnail';

const FileDialogButton = styled.label`
  border: none;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  background: ${({ theme }) => theme.gray.main} url("/assets/img/lesson-text/media.png") no-repeat center;
  background-size: 75%;
  cursor: pointer;
  
  input {
    display: none;
  }
`;

const EditableMediaWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const EditableMediaThumbnail = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const RemoveButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

interface Props {
  media: LineMedia | null;
  onMediaChanged: (url: string) => void;
  onMediaEditStart: () => void;
  onMediaEditEnd: () => void;
  isEditingMedia: boolean;
  onMediaOptionChanged: (name: string, value: string) => void;
  onClearMedia: () => void;
}

const MediaButton: FC<Props> = ({
  media,
  onMediaChanged,
  onMediaEditStart,
  onMediaEditEnd,
  isEditingMedia,
  onMediaOptionChanged,
  onClearMedia,
}) => {
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.target;

    if (!files) {
      return;
    }

    const fileReader = new FileReader();

    fileReader.addEventListener('load', () => {
      if (!fileReader.result) {
        return;
      }

      onMediaChanged(fileReader.result.toString());
    });

    fileReader.readAsDataURL(files[0]);
  }

  const handleMediaEditStart = () => {
    onMediaEditStart();
  }

  const handleMediaEditEnd = () => {
    onMediaEditEnd();
  }

  const handleMediaOptionChanged = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    onMediaOptionChanged(name, value as string);
  }

  if (media && isEditingMedia) {
    return (
      <EditableMediaWrapper>
        <EditableMediaThumbnail>
          <MediaThumbnail
            src={media.url}
            onClick={handleMediaEditEnd}
            selected
          />
          <RemoveButtonWrapper>
            <RemoveButton
              onClick={() => onClearMedia()}
              data-testid="lesson-text-media-attachment-remove-media-button"
            />
          </RemoveButtonWrapper>
        </EditableMediaThumbnail>
        <MediaOptions
          media={media}
          onMediaOptionChanged={handleMediaOptionChanged}
        />
      </EditableMediaWrapper>
    )
  }

  if (media) {
    return (
      <MediaThumbnail
        src={media.url}
        onClick={handleMediaEditStart}
        disabled={media.enqueued}
        faded={media.enqueued}
        data-testid="lesson-text-media-attachment-open-media-options-button"
      />
    );
  }

  return (
    <FileDialogButton
      data-testid="lesson-text-media-attachment-add-media-button"
    >
      <input
        type="file"
        onChange={handleFileChange}

      />
    </FileDialogButton>
  );
}

export default MediaButton;
