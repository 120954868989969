import React, {
  FC,
} from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Subheading from '../ui-kit/Subheading';
import Input from '../ui-kit/Input';
import TagsInput from '../ui-kit/TagsInput';
import Select, { SelectItem } from '../ui-kit/Select';
import HelpBoxTrigger from '../helpbox/HelpBoxTrigger';
import { VideoLanguages } from './types';
import { RootState } from '../store/types';
import {
  setDescription, setLanguage, setTags, setTitle,
} from './videoInformationSlice';

const Wrapper = styled.div``;

const VideoInformation: FC = () => {
  const {
    title,
    language,
    tags,
    description,
  } = useSelector((state: RootState) => state.videoInformation);

  const dispatch = useDispatch();

  const handleTagsChange = (tags: string[]) => {
    dispatch(setTags(tags));
  }

  const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
    const language = e.target.value as VideoLanguages;

    dispatch(setLanguage(language));
  }

  const { t } = useTranslation();

  return (
    <Wrapper>
      <Subheading>
        <Trans i18nKey="videoInformation.headTitle" />
      </Subheading>
      <HelpBoxTrigger
        translationKey="videoInformation.defaultHelpText"
      >
        <Input
          name="title"
          data-testid="video-information-title"
          label={t('videoInformation.title.label')}
          onChange={(e) => dispatch(setTitle(e.target.value))}
          value={title}
        />
      </HelpBoxTrigger>
      <HelpBoxTrigger
        translationKey="videoInformation.defaultHelpText"
      >
        <Input
          name="description"
          data-testid="video-information-description"
          label={t('videoInformation.description.label')}
          onChange={(e) => dispatch(setDescription(e.target.value))}
          value={description}
          multiline
          maxRows={3}
        />
      </HelpBoxTrigger>
      <HelpBoxTrigger
        translationKey="videoInformation.defaultHelpText"
      >
        <TagsInput
          name="tags"
          data-testid="video-information-tags"
          label={t('videoInformation.tags.label')}
          onChange={handleTagsChange}
          value={tags}
        />
      </HelpBoxTrigger>
      <HelpBoxTrigger
        translationKey="videoInformation.defaultHelpText"
      >
        <Select
          name="language"
          data-testid="video-information-language"
          label={t('videoInformation.language.label')}
          value={language}
          onChange={handleSelectChange}
        >
          <SelectItem value={VideoLanguages.ENGLISH}>
            <Trans i18nKey="videoInformation.language.options.english" />
          </SelectItem>
          <SelectItem value={VideoLanguages.GERMAN}>
            <Trans i18nKey="videoInformation.language.options.german" />
          </SelectItem>
          <SelectItem value={VideoLanguages.FRENCH}>
            <Trans i18nKey="videoInformation.language.options.french" />
          </SelectItem>
        </Select>
      </HelpBoxTrigger>
    </Wrapper>
  );
}

export default VideoInformation;
