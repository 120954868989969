import React, {
  FC, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Subheading from '../ui-kit/Subheading';
import Timeline from './Timeline';
import { Line } from '../lesson-text-v2/types';
import Player from './Player';
import Transcript from './Transcript';
import { RootState } from '../store/types';

const Wrapper = styled.div`
`;

const PlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0 50px 0;
`;

const DevButton = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  opacity: 0;
`;

const DevWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 600px;
  z-index: 100;
  
  textarea {
    width: 100%;
    height: 100%;
    outline: none;
  }
`;

const Review: FC = () => {
  const [devVisible, setDevVisible] = useState(false);
  const debugState = useSelector((state: RootState) => state);
  const state = useSelector((state: RootState) => state.lessonText);
  const lines = useMemo(() => state.lines, [state.lines]);

  const media = useMemo(() => state.media, [state.media]);

  const [activeLine, setActiveLine] = useState<Line | null>(null);

  const handleActiveLineChange = (lineIndex: number) => {
    setActiveLine(lines[lineIndex]);
  }

  const handleDevButtonClick = () => {
    setDevVisible(!devVisible);
  }

  return (
    <Wrapper>
      <Subheading>
        <Trans i18nKey="review.headTitle" />
        <DevButton onClick={() => handleDevButtonClick()} />
      </Subheading>
      <PlayerWrapper>
        <Player />
      </PlayerWrapper>
      <Timeline
        lines={lines}
        media={media}
        onLineActive={handleActiveLineChange}
      />
      <Transcript
        line={activeLine}
      />
      {devVisible && (
        <DevWrapper>
          <textarea>
            {JSON.stringify(debugState, null, 2)}
          </textarea>
        </DevWrapper>
      )}
    </Wrapper>
  );
}

export default Review;
