import React, {
  FC, useState,
} from 'react';
import styled from 'styled-components';
import { StyleData } from './data';
import ThumbnailSelectItem from '../ui-kit/ThumbnailSelectItem';

const Wrapper = styled.div`
  margin: 0 10px 0 0;
`;

interface Props {
  item: StyleData;
  isSelected: boolean;
}

const DisplayStyleItem: FC<Props> = ({
  item,
  isSelected,
}) => {
  const [preview, setPreview] = useState(false);

  return (
    <Wrapper
      onMouseOver={() => setPreview(true)}
      onMouseOut={() => setPreview(false)}
    >
      <ThumbnailSelectItem
        data-testid={`display-style-item-${item.slug}`}
        src={preview ? item.previewUrl : item.thumbnailUrl}
        value={item.slug}
        name="displayStyle"
        isSelected={isSelected}
      />
    </Wrapper>
  );
}

export default DisplayStyleItem;
