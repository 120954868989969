export interface EnvironmentSelectionStepData {
  type: Environments | null;
  options: Record<string, string> | null;
}

export enum Environments {
  FOREST = 'forest',
  MOUNTAIN_HOME = 'mountainHome',
  BEACH = 'beach',
  TEST_RANGE = 'testRange'
}

export enum EnvironmentTimeOfDay {
  MORNING = 'morning',
  NOON = 'noon',
  DUSK = 'dusk',
  NIGHT = 'night',
}

export enum EnvironmentWeather {
  SUNNY = 'sunny',
  CLOUDY = 'cloudy',
  STORMY = 'stormy',
  SNOWY = 'snowy',
}
