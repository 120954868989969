import React, { FC } from 'react';
import styled from 'styled-components';
import { Actor } from '../../actors-selection/types';
import Thumbnail from '../common/Thumbnail';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;

const ActorThumbnail = styled.button`
  margin-right: 4px;
  height: 64px;
  cursor: pointer;
  border: none;
  padding: 0;
`;

const PauseActorThumbnail = styled.button`
  border: none;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
  transition: 0.1s ease opacity;
  
  background: linear-gradient(180deg, rgba(82,86,87,1) 0%, rgba(56,56,56,1) 100%);
  background-size: contain;
  border-radius: 8px;
  width: 64px;
  height: 64px;
  
  ${Thumbnail} {
    width: 54px;
    height: 54px;
  }
`;

const pauseActor: Actor = {
  uuid: 'pause',
  name: 'Pause',
  thumbnailUrl: '/assets/img/actor-selection/pause-thumbnail.png',
  fullBodyPreviewUrl: '/assets/img/actor-selection/pause-thumbnail.png',
  slug: 'pause',
};

interface Props {
  actors: Actor[];
  onActorSelected: (actor: Actor) => void;
}
const ActorSelection: FC<Props> = ({
  actors,
  onActorSelected,
}) => (
  <Wrapper
    data-testid="lesson-text-draft-editor-actor-selection"
  >
    {actors.map((actor) => (
      <ActorThumbnail
        onClick={() => onActorSelected(actor)}
        key={actor.uuid}
        data-testid={`lesson-text-draft-editor-actor-selection-${actor.slug}`}
      >
        <Thumbnail src={actor.thumbnailUrl} />
      </ActorThumbnail>
    ))}
    <PauseActorThumbnail
      onClick={() => onActorSelected(pauseActor)}
      data-testid="lesson-text-draft-editor-actor-selection-pause"
    >
      <Thumbnail src="/assets/img/actor-selection/pause-thumbnail.png" />
    </PauseActorThumbnail>
  </Wrapper>
)

export default ActorSelection;
