import {
  Environments,
} from './types';

export interface EnvironmentDataOption {
  slug: string;
}

export type EnvironmentDataOptions = Record<string, EnvironmentDataOption[]>;

export interface EnvironmentData {
  type: Environments;
  label: string;
  options?: EnvironmentDataOptions;
}

interface Data {
  items: EnvironmentData[];
}

// eslint-disable-next-line import/prefer-default-export
export const environments: Data = {
  items: [
    {
      type: Environments.FOREST,
      label: 'forest',
      options: {
        timeOfDay: [
          { slug: 'morning' },
          { slug: 'noon' },
          { slug: 'dusk' },
          { slug: 'night' },
        ],
        weather: [
          { slug: 'sunny' },
          { slug: 'cloudy' },
          { slug: 'stormy' },
          { slug: 'snowy' },
        ],
      },
    },
    {
      type: Environments.BEACH,
      label: 'beach',
      options: {
        timeOfDay: [
          { slug: 'morning' },
          { slug: 'noon' },
          { slug: 'dusk' },
          { slug: 'night' },
        ],
        weather: [
          { slug: 'sunny' },
          { slug: 'cloudy' },
          { slug: 'stormy' },
          { slug: 'snowy' },
        ],
      },
    },
    {
      type: Environments.MOUNTAIN_HOME,
      label: 'mountainHome',
      options: {
        timeOfDay: [
          { slug: 'morning' },
          { slug: 'noon' },
          { slug: 'dusk' },
          { slug: 'night' },
        ],
        weather: [
          { slug: 'sunny' },
          { slug: 'cloudy' },
          { slug: 'stormy' },
          { slug: 'snowy' },
        ],
      },
    },
    { type: Environments.TEST_RANGE, label: 'testRange' },
  ],
};
