import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          videoInformation: {
            headTitle: 'Information about your video',
            // eslint-disable-next-line max-len
            defaultHelpText: 'Give your video a title and a short description. Along with tags, this information will allow students to quickly navigate through the course. The selected language will also impact the available voices for your actors.',
            title: {
              label: 'Video Title',
              helpText: 'Ceva help text',
            },
            description: {
              label: 'Video Description',
              helpText: 'Ceva help text',
            },
            tags: {
              label: 'Tags, comma separated',
              helpText: 'Ceva help text',
            },
            language: {
              label: 'Language',
              helpText: 'Ceva help text',
              options: {
                english: 'English',
                german: 'German',
                french: 'French',
              },
            },
          },
          environmentSelection: {
            headTitle: 'Choose your environment',
            defaultHelpText: 'Customize your environment to better fit the mood of your video. Options include time of day, weather. Each environment might have different customization options.',
            noOptions: 'This environment does not have any configurable parameters.',
            labels: {
              forest: 'Forest',
              beach: 'Beach',
              mountainHome: 'Mountain Home',
              testRange: 'Test Range',
            },
            timeOfDay: {
              label: 'Time of day',
              options: {
                morning: 'Morning',
                noon: 'Noon',
                dusk: 'Dusk',
                night: 'Night',
              },
            },
            weather: {
              label: 'Weather',
              options: {
                sunny: 'Sunny',
                cloudy: 'Cloudy',
                stormy: 'Stormy',
                snowy: 'Snowy',
              },
            },
          },
          actorsSelection: {
            headTitle: 'Choose actors',
            // eslint-disable-next-line max-len
            defaultHelpText: 'Customize each actor’s appearance using the available options. Each actor will have different customization options. You may also add more actors to your video by pressing the “+” button.',
            name: {
              label: 'Actor Name',
            },
            attire: {
              label: 'Attire',
              options: {
                beach: 'Beach',
                hiking: 'Hiking',
                casual: 'Casual',
                formal: 'Formal',
              },
            },
            hat: {
              label: 'Hat',
              options: {
                baseballCap: 'Baseball Cap',
                headUmbrella: 'Head Umbrella',
              },
            },
          },
          displayStyleSelection: {
            defaultHelpText: 'Choose a display style to set the right mood for the video.',
            headTitle: 'Choose the display style',
            styles: {
              epic: 'Epic',
              flick: 'Flick',
              grammy: 'Grammy',
              launch: 'Launch',
              light: 'Light',
              sliced: 'Sliced',
              typed: 'Typed',
            },
          },
          lessonText: {
            headTitle: 'Add lesson text and graphics',
            defaultHelpText: 'Select the speaker by clicking the actor\'s icon in the toolbar',
            bulkInput: 'Bulk Input',
            helpBox: {
              selectSpeaker: 'Select the speaker.',
              draftEditor: 'Enter the text',
            },
            media: {
              editMedia: 'Click to add or edit media options',
              options: {
                overlayType: {
                  label: 'Overlay Type',
                  options: {
                    default: 'Default',
                    pictureInPicture: 'Picture in Picture',
                    sideBySide: 'Side by Side',
                    overlayActor: 'Overlay Actor',
                    space: '3D Space',
                  },
                },
                transitionType: {
                  label: 'Transition Type',
                  options: {
                    default: 'Default',
                    scan: 'Scan',
                    diagonal: 'Diagonal',
                    blur: 'Blur',
                    slide: 'Slide',
                    fade: 'Fade',
                    move: 'Move',
                    swirl: 'Swirl',
                    glitch: 'Glitch',
                    radial: 'Radial',
                    rotate: 'Rotate',
                    leak: 'Leak',
                  },
                },
              },
            },
          },
          review: {
            headTitle: 'Review generated video',
          },
        },
      },
    },
  });

export default i18n;
