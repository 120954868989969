import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { arrayMove } from '@dnd-kit/sortable';
import { Line, LineMedia, LineMediaList } from './types';
import { cleanAllEnqueuedMediaForLine } from './utils';

export interface LessonTextState {
  lines: Line[];
  media: LineMediaList;
}

const initialState: LessonTextState = {
  lines: [],
  media: [],
}

export const lessonTextSlice = createSlice({
  name: 'lessonText',
  initialState,
  reducers: {
    addLine: (state, action: PayloadAction<Line | Line[]>) => {
      if (Array.isArray(action.payload)) {
        state.lines = state.lines.concat(action.payload);
        state.media = state.media.concat(new Array(action.payload.length).fill(null));
        return;
      }
      state.lines.push(action.payload);
      state.media.push(null);
    },
    addLineAtIndex: (state, action: PayloadAction<{ line: Line, index: number }>) => {
      const { line, index } = action.payload;
      state.lines.splice(index, 0, line);
      if (state.media[index] !== null) {
        state.media.splice(index, 0, state.media[index]);
      } else {
        state.media.splice(index, 0, null);
      }
    },
    updateLine: (state, action: PayloadAction<Line>) => {
      state.lines = state.lines.map((line) => {
        if (line.uuid === action.payload.uuid) {
          return action.payload;
        }
        return line;
      });
    },
    updateLineDuration: (state, action: PayloadAction<{ uuid: string, duration: number }>) => {
      state.lines = state.lines.map((line) => {
        if (line.uuid === action.payload.uuid) {
          return {
            ...line,
            duration: action.payload.duration,
          };
        }
        return line;
      });
    },
    moveLine: (state, action: PayloadAction<{ from: number, to: number }>) => {
      const { from, to } = action.payload;

      const lines = arrayMove(state.lines, from, to);
      const media = arrayMove(state.media, from, to);
      const mediaWithCleanEnqueuedItems = media.map(cleanAllEnqueuedMediaForLine(media, to));

      state.lines = lines;
      state.media = mediaWithCleanEnqueuedItems;
    },
    deleteLine: (state, action: PayloadAction<string>) => {
      const lineIndex = state.lines.findIndex((line) => line.uuid === action.payload);
      state.lines = state.lines.filter((_, index) => index !== lineIndex);
      state.media = state.media
        .map(cleanAllEnqueuedMediaForLine(state.media, lineIndex))
        .filter((_, index) => index !== lineIndex);
    },
    setLines: (state, action: PayloadAction<Line[]>) => {
      state.lines = action.payload;
    },
    addMedia: (state, action: PayloadAction<LineMedia>) => {
      const lineIndex = state.lines.findIndex((line) => line.uuid === action.payload.lineUuid);
      if (lineIndex === -1) {
        return;
      }
      state.media[lineIndex] = action.payload;
    },
    setMedia: (state, action: PayloadAction<LineMediaList>) => {
      state.media = action.payload;
    },
  },
});

export const {
  setLines,
  setMedia,
  addMedia,
  addLine,
  addLineAtIndex,
  updateLine,
  updateLineDuration,
  deleteLine,
  moveLine,
} = lessonTextSlice.actions;

export default lessonTextSlice.reducer;
