import { v4 as uuid } from 'uuid';
import { DragEndEvent } from '@dnd-kit/core';
import {
  Draft, LessonTextStepData, Line, LineMedia,
} from './types';
import { Actor } from '../actors-selection/types';
import { ParsedBulkData } from './BulkInput/BulkInputParser';

type CleanAllEnqueuedMediaForLine = (
  media: LessonTextStepData['media'],
  selectedLine: number
) => (lineMedia: LineMedia | null, index: number,) => LineMedia | null;

// TODO: [Possible Improvement]
//  This runs for all lines, could be improved to discard out of range lines?
export const cleanAllEnqueuedMediaForLine: CleanAllEnqueuedMediaForLine = (media, selectedLine) => (
  lineMedia,
  index,
) => {
  const mediaToBeCleaned = media[selectedLine];
  if (lineMedia?.uuid === mediaToBeCleaned?.uuid && lineMedia?.enqueued === true) {
    return null
  }

  return lineMedia;
}

type CleanMediaForSelectedLine = (
  media:LessonTextStepData['media'],
  selectedLine: number,
) => (
  lineMedia: LineMedia | null,
  index: number,
) => LineMedia | null;

export const cleanMediaForSelectedLine: CleanMediaForSelectedLine = (
  media,
  selectedLine,
) => (lineMedia, index) => {
  if (selectedLine === index) {
    return null;
  }
  return lineMedia;
}
type EnqueueMediaInRange = (
  media: LessonTextStepData['media'],
  lines: LessonTextStepData['lines'],
  parentLineIndex: number,
  targetLineIndex: number
) => (lineMedia: LineMedia | null, index: number) => LineMedia | null;

export const enqueueMediaInRange: EnqueueMediaInRange = (
  media,
  lines,
  parentLineIndex,
  targetLineIndex,
) => (
  lineMedia,
  index,
) => {
  // Only check lines in the enqueue range
  // This works as expected when removing (remove enqueued media including the selected one)
  // but when adding it excludes the selected one
  // if (index > parentLineIndex && index < targetLineIndex) {
  if (index > parentLineIndex && index <= targetLineIndex) {
    if (media[parentLineIndex]?.enqueued === true) {
      return null;
    }

    return {
      ...media[parentLineIndex],
      lineUuid: lines[index].uuid,
      enqueued: true,
    } as LineMedia
  }

  return lineMedia;
}

export const canBeEnqueued = (
  currentLineIndex: number,
  selectedLineIndex: number | null,
  lines: LessonTextStepData['lines'],
  media: LessonTextStepData['media'],
) => {
  if (selectedLineIndex === null) {
    return false;
  }

  // All blocks before (and including) the selected line will be excluded
  if (currentLineIndex <= selectedLineIndex) {
    return false;
  }

  const firstLineWithMedia = media.find((lineMedia, index) => index > selectedLineIndex && lineMedia !== null && !lineMedia?.enqueued);
  const firstLineWithMediaPosition = firstLineWithMedia ? media.indexOf(firstLineWithMedia) : undefined;

  if (firstLineWithMediaPosition) {
    return currentLineIndex > selectedLineIndex && currentLineIndex < firstLineWithMediaPosition;
  }

  return currentLineIndex > selectedLineIndex;
}

export const textToDuration = (text: string) => {
  const wordsPerMinute = 163;
  const noOfWords = text.split(/\s/g).length;
  const minutes = noOfWords / wordsPerMinute;

  const seconds = minutes * 60;
  const value = seconds < 1 ? 1 : seconds;
  return +(value).toFixed(2);
}

type IsBulkInput = (text?: string) => boolean;
export const isBulkInput: IsBulkInput = (text) => {
  if (!text) return false;

  return !!(/\r|\n/.exec(text));
};

export const toLine = (draft: Draft, defaultUuid?: string, type?: Line['type']) => {
  const line: Line = {
    uuid: defaultUuid || uuid(),
    type: type || 'text',
    text: draft.text,
    actor: draft?.actor,
    duration: textToDuration(draft.text || ''),
    index: draft.index || -1,
  }

  return line;
}

export const bulkParsedDataToDraft = ({ actorIndex, text }: ParsedBulkData, actors: Actor[]) => {
  if (actors[actorIndex]) {
    return ({
      actor: actors[actorIndex].uuid,
      text,
    } as Draft);
  }

  return null;
}

export const isNewDraft = (
  draftEditorIsVisible: boolean,
  draftLineIndex: number,
  lines: Line[],
) => draftEditorIsVisible && draftLineIndex > lines.length - 1

export const isExistingDraft = (
  draftEditorIsVisible: boolean,
  draftLineIndex: number,
  index: number,
) => draftEditorIsVisible && (index === draftLineIndex);

export const computeIndicesOnDragEnd = (
  e: DragEndEvent,
  lines: Line[],
) => {
  const { active, over } = e;
  const activeLine = lines.find((line) => line.uuid === active.id);
  const overLine = lines.find((line) => line.uuid === over?.id);

  const activeIndex = lines.indexOf(activeLine as Line);
  const overIndex = lines.indexOf(overLine as Line);

  return [activeIndex, overIndex];
}
