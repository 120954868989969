import { v4 as getUuid } from 'uuid';
import { randParagraph } from '@ngneat/falso';
import { StoreData } from '../app/types';
import { VideoLanguages } from '../video-information/types';
import { Environments } from '../environment-selection/types';

const actorIrene = {
  uuid: 'cb1c1f48-5812-41af-bb9a-b89509e8a2ea',
  slug: 'irene',
  name: 'Irene',
  fullBodyPreviewUrl: '/assets/img/actor-selection/irene-default-full-body.png',
  thumbnailUrl: '/assets/img/actor-selection/irene-thumbnail.png',
  options: {
    characterName: 'Irene',
    attire: 'beach',
    hat: 'baseballCap',
  },
};

const actorSteve = {
  uuid: 'cb1c1f48-5812-41af-bb9a-b89508e8a2ea',
  slug: 'steve',
  name: 'Steve',
  thumbnailUrl: '/assets/img/actor-selection/steve-thumbnail.png',
  fullBodyPreviewUrl: '/assets/img/actor-selection/steve-default-full-body.png',
  options: {
    characterName: 'Steve',
    attire: 'casual',
  },
};

const pauseActor = {
  uuid: 'pause',
  name: 'Pause',
  slug: 'pause',
  thumbnailUrl: '/assets/img/lesson-text/pause.png',
  fullBodyPreviewUrl: '/assets/img/lesson-text/pause.png',
}

const generateLinesWithMedia = () => {
  const linesUuids = new Array(10).fill(null).map(() => getUuid());

  const data: StoreData['lessonText'] = {
    lines: [],
    media: [],
  };

  linesUuids.forEach((uuid, index) => {
    const isOdd = index % 2 === 0;
    const text = randParagraph();
    if (index === 9) {
      data.lines.push({
        uuid,
        actor: pauseActor.uuid,
        type: index === 9 ? 'pause' : 'text',
        duration: 1.5,
        index,
      });
    } else {
      data.lines.push({
        uuid,
        actor: isOdd ? actorIrene.uuid : actorSteve.uuid,
        type: 'text',
        text: isOdd ? 'Line' : text,
        duration: 1,
        index,
      });
    }

    if (index === 0) {
      data.media.push({
        uuid: getUuid(),
        lineUuid: uuid,
        url: '/assets/mock1.png',
        enqueued: false,
        options: {
          transitionType: 'default',
          overlayType: 'default',
        },
      });
    }

    if (index === 1) {
      data.media.push(null);
    }

    if (index === 2) {
      data.media.push({
        uuid: getUuid(),
        lineUuid: uuid,
        url: '/assets/mock2.png',
        enqueued: false,
        options: {
          transitionType: 'default',
          overlayType: 'default',
        },
      });
    }

    if (index === 3) {
      data.media.push({
        uuid: data.media[2]?.uuid || '',
        lineUuid: uuid,
        url: '/assets/mock2.png',
        enqueued: true,
        options: {
          transitionType: 'default',
          overlayType: 'default',
        },
      });
    }

    if (index === 4) {
      data.media.push({
        uuid: data.media[2]?.uuid || '',
        lineUuid: uuid,
        url: '/assets/mock2.png',
        enqueued: true,
        options: {
          transitionType: 'default',
          overlayType: 'default',
        },
      });
    }

    if (index > 4) {
      data.media.push(null);
    }
  });

  return data;
}

const generate = () => ({
  videoInformation: {
    title: 'test',
    description: 'test',
    tags: [
      'test1',
      'test2',
    ],
    language: VideoLanguages.ENGLISH,
  },
  environmentSelection: {
    type: Environments.BEACH,
    options: {
      timeOfDay: 'morning',
      weather: 'cloudy',
    },
  },
  actorsSelection: {
    actors: [
      actorIrene,
      actorSteve,
    ],
  },
  displayStyleSelection: {
    displayStyle: 'flick',
  },
  // lessonText: {
  //   lines: [],
  //   media: [],
  // },
  lessonText: generateLinesWithMedia(),
});
export default generate;
