import React, {
  FC, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HelpBoxContext } from './HelpBoxProvider';

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ControlButton = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
`;

const MinimizeButton = styled(ControlButton)`
  background: url("/assets/img/common/minimize.png") no-repeat center;
  background-size: contain;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.tertiary.main};
  border-radius: 10px;
  height: 100%;
  width: 100%;
  padding: 1em;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
`;

interface Props {
  onMinimize: () => void;
  currentStepName: string;
}
const HelpBox: FC<Props> = ({
  onMinimize,
  currentStepName,
}) => {
  const { t } = useTranslation();

  const { helpBoxContent, setHelpBoxContent } = useContext(HelpBoxContext);

  useEffect(() => {
    if (setHelpBoxContent) {
      setHelpBoxContent(t(`${currentStepName}.defaultHelpText`))
    }
    // eslint-disable-next-line
  }, [currentStepName]);

  return (
    <Wrapper>
      <Controls>
        <MinimizeButton onClick={onMinimize} />
      </Controls>
      {helpBoxContent}
    </Wrapper>
  );
}

export default HelpBox;
