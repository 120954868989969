import React, {
  FC, createContext, useState, PropsWithChildren, Dispatch, useMemo,
} from 'react';

interface ContextValues {
  helpBoxContent: string | null;
  setHelpBoxContent: Dispatch<string> | null;
}

export const HelpBoxContext = createContext<ContextValues>({
  helpBoxContent: null,
  setHelpBoxContent: null,
})

const HelpBoxProvider: FC<PropsWithChildren> = ({ children }) => {
  const [helpBoxContent, setHelpBoxContent] = useState<string | null>(null);

  const providerValues = useMemo(() => ({
    helpBoxContent,
    setHelpBoxContent,
  }), [helpBoxContent, setHelpBoxContent]);

  return (
    <HelpBoxContext.Provider
      value={providerValues}
    >
      {children}
    </HelpBoxContext.Provider>
  )
}

export default HelpBoxProvider;
