import { Trans, useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import styled from 'styled-components';
import { SelectProps } from '@mui/material';
import Select, { SelectItem } from '../../ui-kit/Select';
import { LineMedia } from '../types';

const Wrapper = styled.div`
  width: 100%;
  padding: 0 1em;
  
  > .MuiFormControl-root {
    margin-bottom: 10px;
    
    > .MuiInputBase-root {
      > div {
        padding-top: 17px
      }
    }
  }
  
  
  > * > label,
  > * > div {
    font-size: 10px !important;
  }
`;

interface Props {
  media: LineMedia;
  onMediaOptionChanged: SelectProps['onChange'];
}

const MediaOptions: FC<Props> = ({
  media,
  onMediaOptionChanged,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper
      data-testid="lesson-text-media-attachment-media-options"
    >
      <Select
        label={t('lessonText.media.options.overlayType.label')}
        name="overlayType"
        onChange={onMediaOptionChanged}
        value={media.options.overlayType}
        margin="none"
        size="small"
      >
        <SelectItem value="default">
          <Trans i18nKey="lessonText.media.options.overlayType.options.default" />
        </SelectItem>
        <SelectItem value="pictureInPicture">
          <Trans i18nKey="lessonText.media.options.overlayType.options.pictureInPicture" />
        </SelectItem>
        <SelectItem value="sideBySide">
          <Trans i18nKey="lessonText.media.options.overlayType.options.sideBySide" />
        </SelectItem>
        <SelectItem value="overlayActor">
          <Trans i18nKey="lessonText.media.options.overlayType.options.overlayActor" />
        </SelectItem>
        <SelectItem value="space">
          <Trans i18nKey="lessonText.media.options.overlayType.options.space" />
        </SelectItem>
      </Select>
      <Select
        label={t('lessonText.media.options.transitionType.label')}
        name="transitionType"
        onChange={onMediaOptionChanged}
        value={media.options.transitionType}
        margin="none"
        size="small"
      >
        <SelectItem value="default">
          <Trans i18nKey="lessonText.media.options.transitionType.options.default" />
        </SelectItem>
        <SelectItem value="scan">
          <Trans i18nKey="lessonText.media.options.transitionType.options.scan" />
        </SelectItem>
        <SelectItem value="diagonal">
          <Trans i18nKey="lessonText.media.options.transitionType.options.diagonal" />
        </SelectItem>
        <SelectItem value="blur">
          <Trans i18nKey="lessonText.media.options.transitionType.options.blur" />
        </SelectItem>
        <SelectItem value="slide">
          <Trans i18nKey="lessonText.media.options.transitionType.options.slide" />
        </SelectItem>
        <SelectItem value="fade">
          <Trans i18nKey="lessonText.media.options.transitionType.options.fade" />
        </SelectItem>
        <SelectItem value="move">
          <Trans i18nKey="lessonText.media.options.transitionType.options.move" />
        </SelectItem>
        <SelectItem value="swirl">
          <Trans i18nKey="lessonText.media.options.transitionType.options.swirl" />
        </SelectItem>
        <SelectItem value="glitch">
          <Trans i18nKey="lessonText.media.options.transitionType.options.glitch" />
        </SelectItem>
        <SelectItem value="radial">
          <Trans i18nKey="lessonText.media.options.transitionType.options.radial" />
        </SelectItem>
        <SelectItem value="rotate">
          <Trans i18nKey="lessonText.media.options.transitionType.options.rotate" />
        </SelectItem>
        <SelectItem value="leak">
          <Trans i18nKey="lessonText.media.options.transitionType.options.leak" />
        </SelectItem>
      </Select>
    </Wrapper>
  );
}

export default MediaOptions;
