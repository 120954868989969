import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
  AddMediaArgs,
  LineMedia,
} from './types';
import { cleanAllEnqueuedMediaForLine, cleanMediaForSelectedLine, enqueueMediaInRange } from './utils';
import { addMedia, setMedia } from './lessonTextSlice';
import { RootState } from '../store/types';

interface Fields {
  addMedia: (args: AddMediaArgs) => void;
  handleEnqueueConfirm: (targetLineIndex: number, includeSelected: boolean) => void;
  clearMedia: () => void;
  handleMediaOptionChanged: (name: string, value: string) => void;
  selectedLineIndex: number | null;
  handleMediaEditStart: (lineIndex: number) => void;
  handleMediaEditEnd: () => void;
}

type Hook = () => Fields;

const useMediaEditor: Hook = () => {
  const { lines, media } = useSelector((state: RootState) => state.lessonText);

  const [selectedLineIndex, setSelectedLineIndex] = useState<number | null>(null);

  const dispatch = useDispatch();
  const onAddMedia = ({ url, lineIndex }: AddMediaArgs) => {
    const media = {
      uuid: uuid(),
      lineUuid: lines[lineIndex].uuid,
      url,
      enqueued: false,
      options: {
        overlayType: 'default',
        transitionType: 'default',
      },
    };

    dispatch(addMedia(media));
  }

  const handleEnqueueConfirm = (targetLineIndex: number, includeSelected: boolean) => {
    if (selectedLineIndex === null) {
      return;
    }

    // `includeSelected` will be true is the block already contains media
    // so we can affect the selected block when removing/adding media.
    const enqueueRangeEnd = includeSelected ? targetLineIndex - 1 : targetLineIndex

    dispatch(
      setMedia(media
        .map(cleanAllEnqueuedMediaForLine(media, selectedLineIndex))
        .map(enqueueMediaInRange(media, lines, selectedLineIndex, enqueueRangeEnd))),
    );

    setSelectedLineIndex(null);
  }

  const clearMedia = () => {
    if (selectedLineIndex === null) {
      return;
    }

    dispatch(
      setMedia(media
        .map(cleanAllEnqueuedMediaForLine(media, selectedLineIndex))
        .map(cleanMediaForSelectedLine(media, selectedLineIndex))),
    );

    setSelectedLineIndex(null);
  }

  const handleMediaOptionChanged = (name: string, value: string) => {
    dispatch(
      setMedia(
        media.map((lineMedia, index) => {
          if (index === selectedLineIndex) {
            return {
              ...lineMedia,
              options: {
                ...lineMedia?.options,
                [name]: value,
              },
            } as LineMedia;
          }

          return lineMedia;
        }),
      ),
    );
  }

  const handleMediaEditStart = (lineIndex: number) => {
    setSelectedLineIndex(lineIndex);
  }

  const handleMediaEditEnd = () => {
    setSelectedLineIndex(null);
  }

  return {
    addMedia: onAddMedia,
    handleEnqueueConfirm,
    clearMedia,
    handleMediaOptionChanged,
    selectedLineIndex,
    handleMediaEditStart,
    handleMediaEditEnd,
  }
}

export default useMediaEditor;
