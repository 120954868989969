import { Block } from './types';

export const secondsToTime = (seconds: number) => new Date(seconds * 1000).toISOString().slice(14, 19);

export const durationToPercentage = (duration: number, totalDuration: number) => (100 * duration) / totalDuration

export const percentageToDuration = (percentage: number, totalDuration: number) => secondsToTime((percentage * totalDuration) / 100);

export const getLineIndexForPosition = (position: number, blocks: Block[], totalDuration: number) => {
  const blockAtThisPosition = blocks.find((block, index) => {
    const blocksBeforeCurrentBlock = blocks.filter((_block, _index) => _index <= index);
    const sumDurationOfPreviousBlocks = blocksBeforeCurrentBlock.reduce((total, block) => total + block.duration, 0);
    const percentageValueForSumOfPreviousBlocks = durationToPercentage(sumDurationOfPreviousBlocks, totalDuration);

    return position <= percentageValueForSumOfPreviousBlocks;
  });
  return blockAtThisPosition ? blocks.indexOf(blockAtThisPosition) : -1;
}
