import {
  array, min, object, required, string,
} from 'validator-fns';
import { Config } from './types';
import WelcomeScreen from '../welcome-screen';
import VideoInformation from '../video-information';
import EnvironmentSelection from '../environment-selection';
import ActorsSelection from '../actors-selection';
import LessonText from '../lesson-text-v2';
import DisplayStyleSelection from '../display-style-selection';
import Review from '../review';
import {
  optionsValidator,
} from './validators';

const config: Config = {
  steps: [
    {
      stepName: 'welcome',
      Component: WelcomeScreen,
      hideHelpBox: true,
    },
    {
      stepName: 'videoInformation',
      Component: VideoInformation,
      constraints: {
        title: string(
          { trim: true },
          required('validation.required'),
        ),
        description: string(
          { trim: true },
          required('validation.required'),
        ),
        tags: array(
          string(
            required('validation.required'),
          ),
          min(1, 'validation.required'),
        ),
        language: string(
          { trim: true },
          required('validation.required'),
        ),
      },
    },
    {
      stepName: 'environmentSelection',
      Component: EnvironmentSelection,
      constraints: {
        type: string(
          { trim: true },
          required('validation.required'),
        ),
        options: optionsValidator,
      },
    },
    {
      stepName: 'actorsSelection',
      Component: ActorsSelection,
      constraints: {
        actors: array(
          object({
            options: optionsValidator,
          }),
          required('validation.required'),
          min(1, 'validation.required'),
        ),
      },
    },
    {
      stepName: 'displayStyleSelection',
      Component: DisplayStyleSelection,
      constraints: {
        displayStyle: string(
          { trim: true },
          required('validation.required'),
        ),
      },
    },
    {
      stepName: 'lessonText',
      Component: LessonText,
      constraints: {
        lines: array(
          object({
            actor: required('validation.required'),
          }),
          required('validation.required'),
          min(1, 'validation.required'),
        ),
      },
      hideHelpBox: false,
    },
    {
      stepName: 'review',
      Component: Review,
      hideHelpBox: true,
    },
  ],
};

export default config;
