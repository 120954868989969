import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Line } from './types';

import ActorThumbnail from './common/ActorThumbnail';
import { RootState } from '../store/types';
import Thumbnail from './common/Thumbnail';
import TextWrapper from './common/TextWrapper';

const ActorName = styled.p`
  font-size: 12px;
  margin: 0 0 0.5px 0;
  color: ${({ theme }) => theme.gray.label};
`;

const TextContent = styled.p<{ disabled: boolean }>`
  margin: 0;
  line-height: 23px;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

interface Props {
  line: Line;
  onClick?: () => void;
  isEditable?: boolean;
}
const TextLine: FC<Props> = ({
  line,
  onClick,
  isEditable = true,
}) => {
  const actor = useSelector((state: RootState) => state.actorsSelection.actors.find((actor) => actor.uuid === line.actor));

  return (
    <>
      <ActorThumbnail
        onClick={onClick}
        data-testid="lesson-text-line-preview-actor-thumbnail"
      >
        <Thumbnail src={actor ? actor.thumbnailUrl : '/assets/img/actor-selection/unknown-thumbnail.png'} />
      </ActorThumbnail>
      <TextWrapper>
        {line.text && (
          <>
            <ActorName
              data-testid="lesson-text-line-preview-actor-name"
            >
              {actor?.options?.characterName}
            </ActorName>
            <TextContent
              onClick={onClick}
              disabled={!isEditable}
              data-testid="lesson-text-line-preview-text-content"
            >
              {line.text}
            </TextContent>
          </>
        )}
      </TextWrapper>
    </>
  );
}

export default TextLine;
