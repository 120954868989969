import React, { FC } from 'react';
import styled from 'styled-components';
import Button from '../ui-kit/Button';

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 70% 30%;
`;

const ButtonsColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  gap: 30px;
  
  > button {
    max-width: calc(50% - 15px);
  }
`;

interface Props {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  canGoToNextStep: boolean;
  canGoToPreviousStep: boolean;
}

const Navigation: FC<Props> = ({
  goToNextStep,
  goToPreviousStep,
  canGoToNextStep,
  canGoToPreviousStep,
}) => (
  <Wrapper>
    <div>
      &nbsp;
    </div>
    <ButtonsColumn>
      {canGoToPreviousStep && (
        <Button
          disabled={!canGoToPreviousStep}
          onClick={goToPreviousStep}
          variant="outlined"
          fullWidth
          data-testid="app-nav-previous-button"
        >
          Previous
        </Button>
      )}

      <Button
        disabled={!canGoToNextStep}
        onClick={goToNextStep}
        fullWidth
        data-testid="app-nav-next-button"
      >
        Next
      </Button>
    </ButtonsColumn>
  </Wrapper>
)

export default Navigation;
