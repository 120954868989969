import React, { FC } from 'react';
import styled from 'styled-components';
import LineContainer from '../line-container';

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const Label = styled.p`
  padding-left: calc(60px + 64px + 4px + 12px);
  color: ${({ theme }) => theme.gray.label};
`;

interface Props {
  onClick: () => void;
}
const AddNewLine: FC<Props> = ({
  onClick,
}) => (
  <Wrapper
    onClick={onClick}
    data-testid="lesson-text-add-new-line"
  >
    <LineContainer hideActions>
      <Label>
        Click to add new line
      </Label>
    </LineContainer>
  </Wrapper>
)

export default AddNewLine;
