import React, { ChangeEventHandler, FC } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Subheading from '../ui-kit/Subheading';
import DisplayStyleItem from './DisplayStyleItem';
import { displayStyleData } from './data';
import HelpBoxTrigger from '../helpbox/HelpBoxTrigger';
import { RootState } from '../store/types';
import { setDisplayStyle } from './displayStyleSelectionSlice';

const Wrapper = styled.div`

`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DisplayStyleSelection: FC = () => {
  const {
    displayStyle,
  } = useSelector((state: RootState) => state.displayStyleSelection);

  const dispatch = useDispatch();

  const handleDisplayStyleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const style = e.target.value;

    dispatch(setDisplayStyle(style));
  }

  return (
    <Wrapper>
      <Subheading>
        <Trans i18nKey="displayStyleSelection.headTitle" />
      </Subheading>
      <HelpBoxTrigger translationKey="displayStyleSelection.defaultHelpText">
        <GalleryWrapper onChange={handleDisplayStyleChange}>
          {displayStyleData.styles.map((displayStyleData) => (
            <DisplayStyleItem
              key={displayStyleData.name}
              item={displayStyleData}
              isSelected={displayStyle === displayStyleData.slug}
            />
          ))}
        </GalleryWrapper>
      </HelpBoxTrigger>
    </Wrapper>
  );
}

export default DisplayStyleSelection;
