import React, { FC, PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { HelpBoxContext } from './HelpBoxProvider';

interface Props {
  translationKey: string;
}

const Wrapper = styled.span`
  width: auto;
  height: auto;
  display: inherit;
  position: inherit;
`;

const HelpBoxTrigger: FC<PropsWithChildren<Props>> = ({
  children,
  translationKey,
}) => {
  const { t } = useTranslation();

  const { setHelpBoxContent } = useContext(HelpBoxContext);

  const handleMouseOver = () => {
    if (setHelpBoxContent) {
      setHelpBoxContent(t(translationKey));
    }
  }

  return (
    <Wrapper
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
    >
      {children}
    </Wrapper>
  )
}

export default HelpBoxTrigger;
