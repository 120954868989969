import { configureStore } from '@reduxjs/toolkit'
import { videoInformationSlice } from '../video-information/videoInformationSlice';
import { environmentSelectionSlice } from '../environment-selection/environmentSelectionSlice';
import { actorsSelectionSlice } from '../actors-selection/actorsSelectionSlice';
import { displayStyleSelectionSlice } from '../display-style-selection/displayStyleSelectionSlice';
import { lessonTextSlice } from '../lesson-text-v2/lessonTextSlice';
import createMockStoreState from './createMockStoreState';

const MOCK = false;
// eslint-disable-next-line
export const store = configureStore({
  reducer: {
    videoInformation: videoInformationSlice.reducer,
    environmentSelection: environmentSelectionSlice.reducer,
    actorsSelection: actorsSelectionSlice.reducer,
    displayStyleSelection: displayStyleSelectionSlice.reducer,
    lessonText: lessonTextSlice.reducer,
  },
  preloadedState: MOCK ? createMockStoreState() : { },
});
