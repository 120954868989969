import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DisplayStyleSelectionState {
  displayStyle: string | null;
}

const initialState: DisplayStyleSelectionState = {
  displayStyle: null,
}

export const displayStyleSelectionSlice = createSlice({
  name: 'displayStyleSelection',
  initialState,
  reducers: {
    setDisplayStyle: (state, action: PayloadAction<string>) => {
      state.displayStyle = action.payload;
    },
  },
});

export const { setDisplayStyle } = displayStyleSelectionSlice.actions;

export default displayStyleSelectionSlice.reducer;
