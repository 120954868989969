import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import App from './modules/app';
import './index.css';
import './modules/i18n';
import { muiTheme, scTheme } from './modules/theme/theme';
import HelpBoxProvider from './modules/helpbox/HelpBoxProvider';
import { store } from './modules/store/createStore';

// const MOCK = true;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <SCThemeProvider theme={scTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <HelpBoxProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </HelpBoxProvider>
      </MuiThemeProvider>
    </SCThemeProvider>
  </React.StrictMode>,
);
