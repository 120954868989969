import { invalid, valid } from 'validator-fns';

// eslint-disable-next-line
export const optionsValidator = <Options>(value: unknown, field: string | undefined) => {
  if (!value) {
    return invalid({
      message: 'validator.required',
      value,
      field,
    })
  }
  // @ts-ignore
  const isValid = Object.keys(value).every((optionName) => (typeof value[optionName] === 'string' ? value[optionName].length > 0 : value[optionName] !== null));

  if (isValid) {
    return valid({
      value,
      field,
    })
  }

  return invalid({
    message: 'validator.required',
    value,
    field,
  });
}
