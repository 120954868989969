import React, { FC } from 'react';
import PauseLine from './PauseLine';
import TextLine from './TextLine';
import { Line } from './types';

interface Props {
    line: Line;
    onRequestDraftEdit: () => void;
}
const LinePreview: FC<Props> = ({
  line,
  onRequestDraftEdit,
}) => {
  if (line.type === 'text') {
    return (
      <TextLine
        line={line}
        onClick={onRequestDraftEdit}
      />
    )
  }

  if (line.type === 'pause') {
    return (
      <PauseLine
        line={line}
        onClick={onRequestDraftEdit}
      />
    )
  }

  return null;
}

export default LinePreview;
