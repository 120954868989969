import React, { FC, PropsWithChildren } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';

const Wrapper = styled.div<{ transparent: boolean }>`
  width: 100%;
  background: ${({ theme, transparent }) => (transparent ? 'transparent' : theme.gray.main)};
  padding: ${({ transparent }) => (transparent ? '0' : '6px')};
`;

const ScrollingArea = styled.div`
  display: flex;
  
`;

interface Props {
  transparent?: boolean;
}

const HorizontalScrollingGallery: FC<PropsWithChildren<Props>> = ({
  children,
  transparent = false,
}) => (
  <Wrapper transparent={transparent}>
    <ScrollContainer>
      <ScrollingArea>
        {children}
      </ScrollingArea>
    </ScrollContainer>
  </Wrapper>
);

export default HorizontalScrollingGallery;
