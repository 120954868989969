import React, { FC, forwardRef } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

const Input: FC<TextFieldProps> = forwardRef((props, ref) => (
  <TextField
    ref={ref}
    variant="filled"
    margin="normal"
    fullWidth
    {...props}
  />
))

export default Input;
