import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VideoLanguages } from './types';

export interface VideoInformationState {
  title: string | null;
  description: string | null;
  tags: string[];
  language: VideoLanguages | null;
}

const initialState: VideoInformationState = {
  title: null,
  description: null,
  tags: [],
  language: null,
}

export const videoInformationSlice = createSlice({
  name: 'videoInformation',
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.description = action.payload;
    },
    setTags: (state, action: PayloadAction<string[]>) => {
      state.tags = action.payload;
    },
    setLanguage: (state, action: PayloadAction<VideoLanguages>) => {
      state.language = action.payload;
    },
  },
});

export const {
  setTitle,
  setDescription,
  setTags,
  setLanguage,
} = videoInformationSlice.actions;

export default videoInformationSlice.reducer;
