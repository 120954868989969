import styled, { css } from 'styled-components';

const Container = styled.div<{ sm?: boolean }>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1em;

  @media(min-width: 768px) {
    ${({ sm }) => sm && css`
      max-width: 768px;
    `}

    ${({ sm }) => !sm && css`
      max-width: 1200px
    `};
  }
`;

export default Container;
