import React, {
  ChangeEventHandler,
  FC, FocusEventHandler,
  KeyboardEventHandler,
  useState,
} from 'react';
import {
  Box, TextField, Chip,
} from '@mui/material';
import styled from 'styled-components';

interface Props {
  name: string;
  // eslint-disable-next-line
  onChange: (_: string[]) => void;
  value: string[];
  label?: string;
}

const ChipWrapper = styled.span`
  margin-right: 3px;
  > .MuiChip-root {
    height: 24px;
  }
`;

const TagHiddenElement = styled.input`
  display: none;
`;

const TagsInput: FC<Props> = (props) => {
  const {
    name, value = [], onChange, label, ...rest
  } = props;
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value)
  }

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    addTags(e.target.value);
  }

  const addTags = (tagsData: string) => {
    const tags = tagsData.split(',');

    const sanitizedTags = tags.map((tag) => tag.trim());

    const filteredTags = sanitizedTags.filter((tag, index) => tag.length > 0
      && value.indexOf(tag) === -1
      && sanitizedTags.indexOf(tag) === index);

    onChange([
      ...value,
      ...filteredTags,
    ]);
    setInputValue('');
  }

  const deleteTag = (tagName: string) => {
    const newTags = value.filter((tag) => tag !== tagName);
    onChange(newTags);
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (inputValue.length === 0 && e.code === 'Backspace') {
      deleteTag(value[value.length - 1])
    }

    if (e.code === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      addTags(inputValue);
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TextField
        fullWidth
        name={name}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        onBlur={handleOnBlur}
        label={label}
        value={inputValue}
        // @ts-ignore
        data-testid={rest['data-testid']}
        variant="filled"
        margin="normal"
        InputProps={{
          startAdornment: value.length ? (
            <Box sx={{
              display: 'flex',
              marginTop: '20px',
            }}
            >
              {value.map((data) => (
                <ChipWrapper key={`${data}`}>
                  <Chip
                    label={data}
                    onClick={() => deleteTag(data)}
                  />
                  <TagHiddenElement
                    defaultChecked
                    type="checkbox"
                    value={data}
                    name={name}
                  />
                </ChipWrapper>
              ))}
            </Box>
          ) : undefined,
        }}
      />
    </Box>
  );
}
export default TagsInput;
