import { v4 as uuid } from 'uuid';
import { Draft, Line, LineType } from '../types';
import { isBulkInput } from '../utils';

export const emptyDraft = () => ({
  actor: undefined,
  text: '',
} as Draft);

export const draftFromLine = (line: Line) => ({
  actor: line.actor,
  text: line.text,
} as Draft);

type ToDraftsArray = (draft: Draft, defaultUuid?: string, type?: LineType) => Draft[];
export const toDraftsArray: ToDraftsArray = (draft: Draft, defaultUuid?: string, type?: LineType) => {
  if (!draft.text) return [draft];

  if (isBulkInput(draft.text)) {
    return draft.text.split(/\r|\n/)
      .map((text) => ({
        id: defaultUuid || uuid(),
        type: type || 'text',
        text,
        actor: draft?.actor,
      } as Draft));
  }

  return [draft];
}

export const isPauseActor = (actorSlug?: string) => actorSlug === 'pause';
