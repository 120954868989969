import React, { FC } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  MenuItemProps,
  SelectProps,
  Select as OriginalSelect,
} from '@mui/material';

const Select: FC<SelectProps> = ({
  label,
  children,
  margin,
  size,
  ...rest
}) => (
  <FormControl fullWidth margin={margin || 'normal'} variant="filled" size={size}>
    <InputLabel>{label}</InputLabel>
    <OriginalSelect label={label} variant="filled" margin={margin} {...rest}>
      {children}
    </OriginalSelect>
  </FormControl>
)

export const SelectItem: FC<MenuItemProps> = (props) => (
  <MenuItem {...props} />
);

export default Select;
