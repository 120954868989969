import React, { FC } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Subheading from '../ui-kit/Subheading';
import HelpBoxTrigger from '../helpbox/HelpBoxTrigger';
import ActorsGallery from './ActorsGallery';
import { Actor, ActorOptions as IActorOptions } from './types';
import ActorOptions from './ActorOptions';
import { RootState } from '../store/types';
import { setActors } from './actorsSelectionSlice';

const Wrapper = styled.div`

`;

const ActorsSelection: FC = () => {
  const state = useSelector((state: RootState) => state.actorsSelection);

  const dispatch = useDispatch();

  const onActorSelected = (actor: Actor) => {
    dispatch(setActors([
      actor,
      ...state.actors,
    ]));
  }

  const onActorDeleted = (actor: Actor) => {
    dispatch(setActors(state.actors.filter((selectedActor) => selectedActor.uuid !== actor.uuid)));
  }

  const onActorOptionChanged = (actor: Actor, option: IActorOptions) => {
    const newData = state.actors.map((selectedActor) => {
      if (selectedActor.uuid === actor.uuid) {
        return {
          ...selectedActor,
          options: {
            ...selectedActor.options,
            ...option,
          },
        }
      }

      return selectedActor;
    });

    dispatch(setActors(newData));
  }

  return (
    <Wrapper>
      <Subheading>
        <Trans i18nKey="actorsSelection.headTitle" />
      </Subheading>
      <HelpBoxTrigger translationKey="actorsSelection.defaultHelpText">
        {state.actors.length === 0 && (
          <ActorsGallery
            onSelected={onActorSelected}
            defaultCollapsed={false}
          />
        )}

        {state.actors.length > 0 && (
          <ActorsGallery
            onSelected={onActorSelected}
            key={state.actors.length}
          />
        )}

        {state.actors.length > 0 && (
          state.actors.map((selectedActor) => (
            <ActorOptions
              key={selectedActor.uuid}
              actor={selectedActor}
              onChange={onActorOptionChanged}
              onDelete={onActorDeleted}
            />
          ))
        )}
      </HelpBoxTrigger>
    </Wrapper>
  );
}

export default ActorsSelection;
