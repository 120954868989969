export interface ActorDataOption {
  slug: string;
  thumbnailUrl: string;
}

export type ActorDataOptions = Record<string, ActorDataOption[]>;

export interface ActorData {
  slug: string;
  name: string;
  thumbnailUrl: string;
  defaultPreviewUrl: string;
  options?: ActorDataOptions
}

interface Data {
  actors: ActorData[];
}

// eslint-disable-next-line import/prefer-default-export
export const actorsData: Data = {
  actors: [
    {
      name: 'Steve',
      slug: 'steve',
      thumbnailUrl: '/assets/img/actor-selection/steve-thumbnail.png',
      defaultPreviewUrl: '/assets/img/actor-selection/steve-default-full-body.png',
      options: {
        attire: [
          {
            slug: 'casual',
            thumbnailUrl: '/assets/img/actor-selection/steve-casual-full-body.png',
          },
          {
            slug: 'formal',
            thumbnailUrl: '/assets/img/actor-selection/steve-formal-full-body.png',
          },
        ],
      },
    },
    {
      name: 'Irene',
      slug: 'irene',
      thumbnailUrl: '/assets/img/actor-selection/irene-thumbnail.png',
      defaultPreviewUrl: '/assets/img/actor-selection/irene-default-full-body.png',
      options: {
        attire: [
          {
            slug: 'beach',
            thumbnailUrl: '/assets/img/actor-selection/irene-beach-full-body.png',
          },
          {
            slug: 'hiking',
            thumbnailUrl: '/assets/img/actor-selection/irene-hiking-full-body.png',
          },
        ],
        hat: [
          {
            slug: 'headUmbrella',
            thumbnailUrl: '/assets/img/actor-selection/irene-umbrella-hat.jpeg',
          },
          {
            slug: 'baseballCap',
            thumbnailUrl: '/assets/img/actor-selection/irene-baseball-cap.jpg',
          },
        ],
      },
    },
  ],
}
