import { useMemo, useState } from 'react';
import { Config, Step } from './types';

interface Fields {
  currentStepIndex: number;
  currentStep: Step;
  totalSteps: number;

  canGoToNextStep: boolean;
  goToNextStep: () => void;

  canGoToPreviousStep: boolean;
  goToPreviousStep: () => void;
}

interface Args {
  config: Config;
  defaultStepIndex?: number;
}

type Hook = (_:Args) => Fields;

const useNavigation: Hook = ({
  config,
  defaultStepIndex = 0,
}) => {
  const totalSteps = useMemo(() => config.steps.length, [config]);

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(defaultStepIndex);

  const goToNextStep = () => {
    if (currentStepIndex < totalSteps - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  }

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  }

  const canGoToNextStep = currentStepIndex < totalSteps - 1;
  const canGoToPreviousStep = currentStepIndex > 0;

  return {
    currentStep: config.steps[currentStepIndex],
    totalSteps,
    currentStepIndex,
    canGoToNextStep,
    goToNextStep,
    canGoToPreviousStep,
    goToPreviousStep,
  }
}

export default useNavigation;
