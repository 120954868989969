import styled from 'styled-components';

const TextWrapper = styled.div`
  width: 100%;
  padding: 7px 12px 7px;
  
  >div {
    line-height: 23px;
    cursor: pointer;
  }
`;

export default TextWrapper;
