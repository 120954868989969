import styled from 'styled-components';

export const ItemContainer = styled.div`
  height: 270px;
  width: 185px;
  min-width: 185px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
`;

export const Thumbnail = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
