import React, {
  ChangeEventHandler, FC, useMemo,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Subheading from '../ui-kit/Subheading';
import HorizontalScrollingGallery from '../ui-kit/HorizontalScrollingGallery';
import HelpBoxTrigger from '../helpbox/HelpBoxTrigger';
import { Environments } from './types';
import Select, { SelectItem } from '../ui-kit/Select';
import { environments } from './data';
import ThumbnailSelectItem from '../ui-kit/ThumbnailSelectItem';
import { RootState } from '../store/types';
import { setEnvironmentOptions, setEnvironmentType } from './environmentSelectionSlice';

const EnvironmentSettings = styled.div``;

const GalleryWrapper = styled.div`
  overflow: hidden;
`;

const NoEnvironmentOptionsLabel = styled.div`
  margin-top: 16px;
  padding: 16px 12px;
`;

const EnvironmentSelection: FC = () => {
  const state = useSelector((state: RootState) => state.environmentSelection);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const environmentData = useMemo(() => environments.items.find((environment) => environment.type === state.type), [state.type]);

  const handleEnvironmentTypeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const environment = e.target.value as Environments;
    const newEnvironmentData = environments.items.find((environmentData) => environmentData.type === environment);

    const environmentOptions = {};

    Object.keys(newEnvironmentData?.options || {}).forEach((environmentOptionName) => {
      // @ts-ignore
      environmentOptions[environmentOptionName] = null;
    });

    dispatch(setEnvironmentType(environment));
    dispatch(setEnvironmentOptions(environmentOptions));
  };

  const handleOptionChange = (e: SelectChangeEvent<unknown>) => {
    const name = e.target.name as string;
    const value = e.target.value as string;

    dispatch(setEnvironmentOptions({
      ...state.options,
      [name]: value,
    }));
  }

  return (
    <div>
      <Subheading>
        <Trans i18nKey="environmentSelection.headTitle" />
      </Subheading>

      <HelpBoxTrigger translationKey="environmentSelection.defaultHelpText">
        <GalleryWrapper onChange={handleEnvironmentTypeChange}>
          <HorizontalScrollingGallery>
            {environments.items.map((environment) => (
              <ThumbnailSelectItem
                key={environment.type}
                src={`/assets/img/environments/${environment.type}.png`}
                value={environment.type}
                name="environmentName"
                label={t(`environmentSelection.labels.${environment.label}`)}
                isSelected={state.type === environment.type}
              />
            ))}
          </HorizontalScrollingGallery>
        </GalleryWrapper>

        {state.type && environmentData && (
          <EnvironmentSettings>
            {!environmentData.options && (
              <NoEnvironmentOptionsLabel>
                <Trans i18nKey="environmentSelection.noOptions" />
              </NoEnvironmentOptionsLabel>
            )}
            {environmentData.options && Object.keys(environmentData.options).map((optionName) => (
              <Select
                key={optionName}
                name={optionName}
                label={t(`environmentSelection.${optionName}.label`)}
                onChange={handleOptionChange}
                value={state.options && state.options[optionName]}
              >
                {environmentData.options && environmentData.options[optionName].map((value) => (
                  <SelectItem
                    value={value.slug}
                    key={value.slug}
                  >
                    <Trans i18nKey={`environmentSelection.${optionName}.options.${value.slug}`} />
                  </SelectItem>
                ))}
              </Select>
            ))}
          </EnvironmentSettings>
        )}
      </HelpBoxTrigger>
    </div>
  );
}

export default EnvironmentSelection;
